import html2pdf from 'html2pdf.js';

export const downloadInvoicesAsPDF = (currentItems, branchPic, branchName, branchLocation, branchPhone) => {
  const container = document.createElement('div');

  currentItems?.forEach((invoice, index) => {
    const invoiceHTML = `
      <div class="invoice" style="page-break-after: always; font-family: Arial, sans-serif; max-width: 800px; margin: 0 auto; padding: 20px;">
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
          <img src='${branchPic}' alt="Kaya Spa" style="max-width: 100px;">
          <div style="text-align: right;">
            <h2 style="margin: 0;">INVOICE</h2>
            <p style="margin: 5px 0;">Invoice No. - ${invoice?.invoiceNumber}</p>
            <p style="margin: 5px 0;">Date/Time - ${new Date(invoice?.date).toLocaleDateString()}, ${new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}</p>
          </div>
        </div>
        
        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <div>
            <h3 style="margin: 0 0 10px 0;">Invoice From:</h3>
            <p style="margin: 0;">${branchName}</p>
            <p style="margin: 0;">AT - ${branchLocation}</p>
            <p style="margin: 0;">${branchPhone}</p>
          </div>
          <div style="text-align: right;">
            <h3 style="margin: 0 0 10px 0;">Invoice To:</h3>
            <p style="margin: 0;">${invoice?.billingInfo?.name || ""}</p>
            <p style="margin: 0;">${invoice?.billingInfo?.phone || ""}</p>
            <p style="margin: 0;">Wallet Bal.: Rs.${invoice?.walletApplied?.toFixed(2) || "0.00"}/-</p>
            <p style="margin: 0;">Loyalty Point Bal: 0.00/-</p>
          </div>
        </div>
        
        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px; border: 1px solid #ddd;">
          <tr style="background-color: #f2994a; color: white;">
            <th style="padding: 10px; text-align: left; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">SL NO</th>
            <th style="padding: 10px; text-align: left; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">SERVICES</th>
            <th style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">QTY</th>
            <th style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">AMOUNT</th>
            <th style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">DISCOUNT</th>
            <th style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">TOTAL AMOUNT</th>
          </tr>
          ${invoice?.services?.map((service, index) => `
            <tr style="background-color: ${index % 2 === 0 ? '#f9f9f9' : 'white'};">
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${index + 1}</td>
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${service?.serviceName} (${service?.duration})</td>
              <td style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${service?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">₹${service?.itemPerPrice * service?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${service?.discount || ""} ${service?.discountType === "percentage" ? "%" : ""}</td>
              <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">₹${service?.price}</td>
            </tr>
          `).join('')}
          ${invoice?.products?.map((product, index) => `
            <tr style="background-color: ${(invoice?.services?.length + index) % 2 === 0 ? '#f9f9f9' : 'white'};">
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${invoice?.services?.length + index + 1}</td>
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${product?.productName}</td>
              <td style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${product?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">₹${product?.itemPerPrice * product?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${product?.discount || ""} ${product?.discountType === "percentage" ? "%" : ""}</td>
              <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">₹${product?.price}</td>
            </tr>
          `).join('')}
          ${invoice?.membershipIds?.map((member, index) => `
            <tr style="background-color: ${(invoice?.services?.length + invoice?.products?.length + index) % 2 === 0 ? '#f9f9f9' : 'white'};">
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${invoice?.services?.length + invoice?.products?.length + index + 1}</td>
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${member?.packageName || ""}</td>
              <td style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">1</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">₹${member?.price || 0}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${member?.discount || 0} ${member?.discountType === "percentage" ? "%" : ""}</td>
              <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">₹${(member?.price || 0) - (member?.discount || 0)}</td>
            </tr>
          `).join('')}
        </table>
        
        <div style="margin-bottom: 20px;">
          <p style="margin: 5px 0; text-align: right;">Amount: ₹${invoice?.finalPrice}</p>
          <p style="margin: 5px 0; text-align: right;">Total (Rounded off): ₹${invoice?.finalPrice}</p>
          <p style="margin: 5px 0; text-align: right;">Paid Amount: ₹${invoice?.paidAmount}</p>
        </div>
        
        <div>
          <h3 style="margin: 0 0 10px 0;">Terms & Conditions</h3>
          <ul style="margin: 0; padding-left: 20px;">
            <li>Money Once Paid Shall not be refunded</li>
            <li>Services are not transferable</li>
          </ul>
        </div>
      </div>
    `;

    container.innerHTML += invoiceHTML;
  });

  const opt = {
    margin: 10,
    filename: 'invoices.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };

  html2pdf().from(container).set(opt).save();
};
