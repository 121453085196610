import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { decryptData, getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import axios from "axios";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import { useEffect } from "react";
import Plus from "../../../Assets/billAdd.png";
import { LuLoader2 } from "react-icons/lu";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  outline: "none",
};

const AddProducts = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [volumeUnit, setVolumeUnit] = useState("Ml");
  const [image, setImage] = useState();
  const [imageErr, setImageErr] = useState();
  const [categories, setCategories] = useState([]);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
  const [categoryName, setCategoryName] = useState("");
  const [showAddCategory, setShowAddCategory] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: "",
      mrp: "",
      priceProcurement: "",
      quantity: "",
      volume: "",
      code: "",
      mfg: "",
      exp: "",
      hsn: "",
      type: "",
      category: "",
      brand: "",
      categoryName: ""
    }
  });

  useEffect(() => {
    getCategories()
  }, [props.openAddProduct])

  const getCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/category/find/${branch?._id}
        `, {
        headers: {
          "Authorization": token,
        },
      });
      const data = response?.data?.data || [];
      setCategories(data);
    } catch (error) {
      console.log("error", error)
    }
  }

  const addCategories = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/category/add`, {
        category: categoryName,
        organizationId: branch?._id
      }, {
        headers: {
          "Authorization": token,
        },
      });
      await getCategories();
      setCategoryName('');
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false)
    } finally {
      setIsLoading(false);
    }
  }

  const createNewProductHistory = async (id, quantity) => {
    const createdBy =
      decryptData()?.user?.role === "admin"
        ? {
          name: decryptData()?.user?.firstName + " " + decryptData()?.user?.lastName,
          adminId: decryptData()?.userTypeData?._id,
        }
        : {
          name: decryptData()?.user?.firstName + decryptData()?.user?.lastName,
          staffId: decryptData()?.userTypeData?._id,
        };

    const data = JSON.stringify({
      productId: id,
      date: new Date(),
      type: "IN",
      stockType: "New Product",
      adminId: branch?.adminId,
      organizationId: branch?._id,
      quantity: quantity,
      beforeStock: 0,
      afterStock: quantity,
      createdBy: createdBy,
    });

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/stockLedger/add`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      });
    } catch (error) {
      console.log("rrrrrrrr", error);
    }
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    let imageData;

    try {
      if (image) {
        const formData2 = new FormData();
        formData2.append('file', image);
        formData2.append('upload_preset', 'i_bar_profile');

        const Response = await fetch(
          `https://api.cloudinary.com/v1_1/dprc07xle/image/upload`,
          {
            method: 'POST',
            body: formData2,
          }
        );
        imageData = await Response.json();
      }

      const productData = {
        name: formData.name,
        company: formData.company,
        costPriceMRP: formData.mrp,
        priceProcurementPrice: formData.priceProcurement,
        quantity: formData.quantity,
        volume: volumeUnit === "Ltr" ? formData.volume * 1000 : formData.volume,
        code: formData.code,
        mfgDate: formData.mfg,
        expDate: formData.exp,
        HSN_SAC: formData.hsn,
        type: formData.type,
        brand: formData.brand,
        volumeUnit: volumeUnit,
        category: formData.category,
        url: imageData?.secure_url || "",
        organizationId: branch?._id,
        totalVolume: volumeUnit === "Ltr" ? formData.volume * formData.quantity * 1000 : formData.volume * formData.quantity
      };

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/product/add`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: productData,
      });

      await props.fetchProducts();
      await createNewProductHistory(response?.data?.data?._id, formData.quantity);
      tostifySuccess(response?.data?.message);
      props.setOpenAddProduct(false);
      reset();
      setImage(null);
    } catch (error) {
      console.log("add Product error:", error);
      tostifyErr(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={props.openAddProduct}
      onClose={() => {
        props.setOpenAddProduct(false);
        reset();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="relative">
        <div className="no-scrollbar max-h-[500px] overflow-x-auto px-4">
          <div className="btn-wrapper text-center">
            <span
              onClick={() => {
                props.setOpenAddProduct(false);
                reset();
              }}
              className="absolute top-6 right-5 text-3xl cursor-pointer"
            >
              x
            </span>
            <div className="text-xl text-[#2b2f32] font-bold">
              Add Product
            </div>
          </div>

          <div className="flex-auto mt-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  Product Image
                </div>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => {
                    setImageErr(false);
                    setImage(e.target.files[0]);
                  }}
                  className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${imageErr ? 'ring-[#f7003f]' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150`}
                />
              </div>

              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  Name
                  <span className="text-red-600 text-lg"> *</span>
                </div>
                <input
                  {...register("name", { required: "Name is required" })}
                  className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.name ? 'ring-[#f7003f]' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150`}
                  placeholder="Enter Product Name"
                />
                {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>}
              </div>

              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  Type
                  <span className="text-red-600 text-lg"> *</span>
                </div>
                <select
                  {...register("type", { required: "Type is required" })}
                  className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.type ? 'ring-[#f7003f]' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150`}
                >
                  <option value="">Select Type</option>
                  <option value="retail">Retail</option>
                  <option value="consumable">Consumable</option>
                </select>
                {errors.type && <p className="text-red-500 text-xs mt-1">{errors.type.message}</p>}
              </div>
              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  Category
                  <span className="text-red-600 text-lg"> *</span>
                </div>
                <div className="flex gap-2 items-center">
                  <select
                    {...register("category", { required: "Category is required" })}
                    className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.category ? "ring-[#f7003f]" : "ring-[#845ff6]"
                      } w-full ease-linear transition-all duration-150`}
                  >
                    <option value="">Select Category</option>
                    {categories?.map((ele) => (
                      <option key={ele._id} value={ele._id}>
                        {ele.category}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={() => setShowAddCategory(!showAddCategory)}
                    className="text-lg bg-primaryColor text-white px-2 py-2 rounded"
                  >
                    {showAddCategory ? "Cancel" : "Add"}
                  </button>
                </div>
                {errors.category && <p className="text-red-500 text-xs mt-1">{errors.category.message}</p>}
              </div>

              {showAddCategory && (
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Category Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <input
                      name="categoryName"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                      className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.categoryName ? "ring-[#f7003f]" : "ring-[#845ff6]"
                        } w-full ease-linear transition-all duration-150`}
                      placeholder="Enter Category Name"
                    />
                    {isLoading ? <LuLoader2 className="animate-spin text-primaryColor text-xl" /> : <img
                      onClick={addCategories}
                      src={Plus}
                      alt="Add"
                      className="h-6 w-6 cursor-pointer"
                    />}
                  </div>
                  {errors.categoryName && (
                    <p className="text-red-500 text-xs mt-1">{errors.categoryName.message}</p>
                  )}
                </div>
              )}


              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  Brand
                  <span className="text-red-600 text-lg"> *</span>
                </div>
                <input
                  {...register("brand", { required: "Brand is required" })}
                  className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.brand ? 'ring-[#f7003f]' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150`}
                  placeholder="Enter Brand Name"
                />
                {errors.brand && <p className="text-red-500 text-xs mt-1">{errors.brand.message}</p>}
              </div>

              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  MRP
                  <span className="text-red-600 text-lg"> *</span>
                </div>
                <input
                  {...register("mrp", { required: "MRP is required" })}
                  type="number"
                  className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.mrp ? 'ring-[#f7003f]' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150`}
                  placeholder="Enter MRP"
                />
                {errors.mrp && <p className="text-red-500 text-xs mt-1">{errors.mrp.message}</p>}
              </div>

              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  Price Procurement
                </div>
                <input
                  {...register("priceProcurement")}
                  type="number"
                  className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                  placeholder="Price Procurement"
                />
              </div>

              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  Quantity
                  <span className="text-red-600 text-lg"> *</span>
                </div>
                <input
                  {...register("quantity", { required: "Quantity is required" })}
                  type="number"
                  className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.quantity ? 'ring-[#f7003f]' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150`}
                  placeholder="Enter Quantity"
                />
                {errors.quantity && <p className="text-red-500 text-xs mt-1">{errors.quantity.message}</p>}
              </div>

              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  {volumeUnit === "Pcs" ? "Pcs" : "Volume"}
                  <span className="text-red-600 text-lg"> *</span>
                </div>
                <div className="flex">
                  <input
                    {...register("volume", { required: "Volume is required" })}
                    type="number"
                    className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.volume ? 'ring-[#f7003f]' : 'ring-[#845ff6]'} w-3/4 ease-linear transition-all duration-150`}
                    placeholder={`Enter Product ${volumeUnit === "Pcs" ? "Pcs" : "Volume"}`}
                  />
                  <select
                    value={volumeUnit}
                    onChange={(e) => setVolumeUnit(e.target.value)}
                    className="ml-2 px-3 py-3 bg-white rounded shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-1/4 ease-linear transition-all duration-150"
                  >
                    <option value="Ml">Ml</option>
                    <option value="Ltr">Ltr</option>
                    <option value="Pcs">Pcs</option>
                  </select>
                </div>
                {errors.volume && <p className="text-red-500 text-xs mt-1">{errors.volume.message}</p>}
              </div>

              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  Code
                </div>
                <input
                  {...register("code")}
                  type="text"
                  className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                  placeholder="Enter Product Code"
                />
              </div>

              <div className="flex justify-center items-center mb-3 w-96">
                <div className="relative w-full">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Mfg
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    {...register("mfg", { required: "Manufacturing date is required" })}
                    type="date"
                    className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.mfg ? 'ring-[#f7003f]' : 'ring-[#845ff6]'
                      } w-full ease-linear transition-all duration-150`}
                  />
                  {errors.mfg && <p className="text-red-500 text-xs mt-1">{errors.mfg.message}</p>}
                </div>

                <div className="relative w-full ml-6">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Exp
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    {...register("exp", { required: "Expiry date is required" })}
                    type="date"
                    className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.exp ? 'ring-[#f7003f]' : 'ring-[#845ff6]'
                      } w-full ease-linear transition-all duration-150`}
                  />
                  {errors.exp && <p className="text-red-500 text-xs mt-1">{errors.exp.message}</p>}
                </div>
              </div>

              <div className="relative w-full mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                  HSN_SAC
                </div>
                <input
                  {...register("hsn")}
                  type="text"
                  className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                  placeholder="HSN_SAC"
                />
              </div>

              <div className="w-full flex items-center justify-center mt-5">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                >
                  {isLoading ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    <span>Add</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
export default AddProducts;