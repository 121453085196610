import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import {
  appointmentWithoutLimit,
  fetchCustomerWithMembership,
} from "../../../Screens/Common/initData";
import {
  allAppointmentList,
  allCustomerWithMembership,
  setAppointmentFromCalendar,
} from "../../../state-management/main/actions";
import Plus from "../../../Assets/billAdd.png";
import minus from "../../../Assets/billRemove.png";
import { IoIosRadioButtonOff } from "react-icons/io";
import InvoiceDropdown from "./InvoiceDropdown";

const AppointmentModal = ({ setSelectedRow }) => {
  const appointmentDetailFromCalendar = useSelector(
    (state) => state?.main?.appointmentDetailFromCalendar
  );
  const appointment = appointmentDetailFromCalendar?.appointment;

  const [formData, setFormData] = useState({
    firstname: appointment?.customerName || "",
    phoneNumber: appointment?.customerPhone?.slice(3, 13) || "",
    email: appointment?.email || "",
    gender: appointment?.gender || "",
    dateOfBirth: "",
    appointmentDate: appointment?.appointmentDate
      ? getCurrentDateTimes(appointment?.appointmentDate)
      : getCurrentDateTime(),
    selectstaff: "",
    note: appointment?.note ? appointment?.note : "",
    selectedServices: appointment?.services
      ? appointment?.services
      : [{ serviceId: "", staffId: "" }],
  });
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState({});
  const [servicesArr, setServicesArr] = useState([]);
  const [staffArr, setStaffArr] = useState([]);
  const [checked, setChecked] = useState({
    quickInquiry: false,
  });
  const [countryCode, setCountryCode] = useState("+91");
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const inventory = useSelector((state) => state?.main?.inventoryService);
  const staffList = useSelector((state) => state?.main?.staffList);
  const branch = useSelector((state) => state?.main?.branch) || "";
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setFormData((prev) => ({
      firstname: appointment?.customerName || "",
      phoneNumber: appointment?.customerPhone?.slice(3, 13) || "",
      email: appointment?.email || "",
      gender: appointment?.gender || "",
      dateOfBirth: "",
      appointmentDate: appointment?.appointmentDate
        ? getCurrentDateTimes(appointment?.appointmentDate)
        : getCurrentDateTime(),
      selectstaff: "",
      note: appointment?.note ? appointment?.note : "",
      selectedServices: appointment?.services?.length > 0
        ? appointment?.services?.map((service) => ({
          serviceId: service.serviceId?._id || "",
          staffId: service?.staffId?._id || "",
        }))
        : [{ serviceId: "", staffId: "" }],
    }));
  }, [appointment, branch]);

  const currentTime = getCurrentDateTime();
  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0");
    const day = `${now.getDate()}`.padStart(2, "0");
    const hour = `${now.getHours()}`.padStart(2, "0");
    const minute = `${now.getMinutes()}`.padStart(2, "0");
    return `${year}-${month}-${day}T${hour}:${minute}`;
  }
  function getCurrentDateTimes(date) {
    if (!(date instanceof Date) || isNaN(date)) {
      date = new Date();
    }
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const day = `${date.getDate()}`.padStart(2, "0");
    const hour = `${date.getHours()}`.padStart(2, "0");
    const minute = `${date.getMinutes()}`.padStart(2, "0");
    return `${year}-${month}-${day}T${hour}:${minute}`;
  }

  useEffect(() => {
    const filterBranchServices = inventory?.filter(
      (ele) => ele?.organizationId?._id === branch?._id
    );
    const filteredStaffs = staffList?.filter(
      (ele) => ele?.organizationId === branch?._id
    );
    setStaffArr(filteredStaffs);
    setServicesArr(filterBranchServices);
  }, [inventory, branch?._id]);

  const allApointmentData = async () => {
    const data = await appointmentWithoutLimit();
    dispatch(allAppointmentList(data));
  };

  const getCustmerWithMembership = async () => {
    const data = await fetchCustomerWithMembership();
    dispatch(allCustomerWithMembership(data));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const errors = {};

    if (!formData?.firstname?.trim()) {
      errors.firstname = "Name is required";
      tostifyErr("Please Enter the Name")
    }

    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
    }

    if (!formData.gender) {
      errors.gender = "Gender is required";
      tostifyErr("Please Select the Gender")
    }

    if (!formData.appointmentDate.trim()) {
      errors.appointmentDate = "Appointment Date is required";
    }

    if (!appointment || Object.keys(appointment).length === 0) {
      if (Object.keys(errors).length === 0) {
        createUser();
      } else {
        setValidationErrors(errors);
      }
    } else {
      updateAppointment();
    }
  };

  const sendRescheduleSms = async () => {
    let data = JSON.stringify({
      body: `Your appointment has been Rescheduled to date-${new Date(
        formData?.appointmentDate
      )?.toLocaleString()} `,
      to: "+916371638082",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/twilio/send-sms`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        tostifySuccess(response?.data?.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateAppointment = async () => {
    const obj = {
      note: formData?.note,
      appointmentDate: formData?.appointmentDate,
      status: "rescheduled",
      services: formData?.selectedServices,
      rescheduledInfo: {
        oldAppointmentDate: appointment?.date,
        reason: formData?.reason,
      },
    };
    if (obj.services) {
      const validServices = obj?.services?.filter(service =>
        service.serviceId && service.staffId
      );

      if (validServices?.length === 0) {
        delete obj.services
      }
    }
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/appointment/update/${appointment?._id}`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: obj,
    };
    setIsLoading(true);
    await axios
      .request(config)
      .then(async (response) => {
        await allApointmentData();
        dispatch(
          setAppointmentFromCalendar({
            ...appointmentDetailFromCalendar,
            appointment: {},
          })
        );
        setIsLoading(false);
        setFormData({ ...formData, selectedServices: [{ serviceId: "", staffId: "" }] });
        setSelectedRow(null);
        tostifySuccess(response?.data?.message);
        await sendRescheduleSms();
        // closeModal();
      })
      .catch((error) => {
        tostifyErr(error?.response?.data?.message);
        dispatch(
          setAppointmentFromCalendar({
            ...appointmentDetailFromCalendar,
            appointment: {},
          })
        );
        // closeModal();
        setIsLoading(false);
      });
  };

  const createUser = async () => {
    const obj = {
      organizationId: branch?._id,
      name: formData.firstname,
      phoneNumber: countryCode + formData.phoneNumber,
      email: formData.email,
      gender: formData?.gender,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/customer/add/`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: obj,
    };

    setIsLoading(true);
    await axios
      .request(config)
      .then((response) => {
        creteAppointmentFunc();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("create User error:", error?.response?.data);
        if (
          error?.response?.data?.message ===
          "Customer already exists in the same branch."
        ) {
          creteAppointmentFunc();
        } else {
          tostifyErr(error?.response?.data?.message);
          setIsLoading(false);
        }
      });
  };
  const creteAppointmentFunc = async () => {
    setIsLoading(true);
    const obj = {
      organizationId: branch?._id,
      adminId: branch?.adminId,
      customerName: formData.firstname,
      customerPhone: countryCode + formData.phoneNumber,
      gender: formData?.gender,
      appointmentDate: formData.appointmentDate,
      services: formData?.selectedServices,
      note: formData.note,
      inquiry: checked.quickInquiry,
    };

    if (obj.services) {
      const validServices = obj?.services?.filter(service =>
        service.serviceId && service.staffId
      );

      if (validServices?.length === 0) {
        delete obj.services
      }
    }


    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/appointment/add/`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: obj,
    };
    setIsLoading(true);
    await axios
      .request(config)
      .then((response) => {
        // console.log(response?.data);
        tostifySuccess(response?.data?.message);
        setFormData((prev) => ({
          firstname: "",
          phoneNumber: "",
          email: "",
          gender: "",
          selectedServices: [{ serviceId: "", staffId: "" }],
          note: "",
        }));
        setChecked({
          quickInquiry: "",
        });
        getCustmerWithMembership();
        allApointmentData();
        setIsLoading(false);
      })
      .catch((error) => {
        tostifyErr("Something went wrong");
        console.log("create appointment error:", error);
        setIsLoading(false);
      });
  };

  const handleServiceChange = (e, index, type) => {
    const { value } = e.target;
    const updatedServices = [...formData.selectedServices];
    if (type === "service") {
      updatedServices[index] = { ...updatedServices[index], serviceId: value };
    }
    if (type === "staff") {
      updatedServices[index] = { ...updatedServices[index], staffId: value };
    }
    setFormData({
      ...formData,
      selectedServices: updatedServices,
    });
  };

  const addServiceField = () => {
    setFormData({
      ...formData,
      selectedServices: [
        ...formData.selectedServices,
        { serviceId: "", staffId: "" },
      ],
    });
  };
  const removeServiceField = (index) => {
    if (formData?.selectedServices?.length === 1) {
      return;
    }
    const updatedServices = formData?.selectedServices?.filter(
      (_, i) => i !== index
    );
    setFormData({
      ...formData,
      selectedServices: updatedServices,
    });
  };

  const handleToggle = (item) => {
    if (item === "quickInquiry") {
      setChecked((prevChecked) => ({
        ...prevChecked,
        quickInquiry: !prevChecked.quickInquiry,
      }));
    }
  };

  const handleGenderSelect = (value) => {
    setFormData(prev => ({
      ...formData,
      gender: value?.toLowerCase(),
    }))
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4 flex justify-start flex-wrap gap-6">
          <div className="w-full md:w-1/3 flex items-center justify-start gap-4">
            <div className="w-1/5">
              <label className="text-gray-700 text-sm font-bold mb-2">
                Code
              </label>
              <select
                name=""
                id=""
                onChange={(e) => setCountryCode(e.target.value)}
                className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
               focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
              >
                <option value="+91">+91</option>
              </select>
            </div>
            <div className="w-4/5">
              <label
                className="text-gray-700 text-sm font-bold mb-2"
                htmlFor="phoneNumber"
              >
                Phone :<span className="text-red-600 text-lg"> *</span>
              </label>
              <input
                className={
                  formData?.phoneNumber?.length > 10
                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                }
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Enter Phone Number"
                value={formData.phoneNumber}
                onChange={(e) => {
                  handleChange(e);
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    phoneNumber: "",
                  }));
                }}
              />
              {validationErrors.phoneNumber && (
                <p className="text-red-500 text-[10px]">
                  {validationErrors.phoneNumber}
                </p>
              )}
            </div>
          </div>

          <div className="w-full md:w-1/3">
            <label
              className="text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name:<span className="text-red-600 text-lg"> *</span>
            </label>
            <input
              className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
              focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
              type="text"
              id="firstname"
              name="firstname"
              placeholder="Enter Name"
              value={formData?.firstname}
              onChange={(e) => {
                handleChange(e);
                setValidationErrors((prevErrors) => ({
                  ...prevErrors,
                  firstname: "",
                }));
              }}
            />
            {validationErrors.firstname && (
              <p className="text-red-500 text-[10px]">
                {validationErrors.firstname}
              </p>
            )}
          </div>

          <div className="w-full md:w-1/5">
            <label
              className="block text-gray-700 text-sm font-bold mb-1"
              htmlFor="gender"
            >
              Gender
              <sup className="text-[#FF782D] text-[14px]">*</sup>
            </label>
            <div className="w-full">
              <InvoiceDropdown
                items={["Male", "Female", "Other"]}
                onSelect={handleGenderSelect}
                selectedItem={formData?.gender}
                label={"Select"}
                className="w-full"
              />
            </div>
          </div>

          <div className="w-full md:w-1/3">
            <label
              className="text-gray-700 text-sm font-bold mb-2"
              htmlFor="appointmentDate"
            >
              Date
            </label>
            <input
              className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
              focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
              type="datetime-local"
              id="appointmentDate"
              name="appointmentDate"
              value={formData.appointmentDate}
              onChange={(e) => {
                handleChange(e);
                setValidationErrors((prevErrors) => ({
                  ...prevErrors,
                  appointmentDate: "",
                }));
              }}
            />
            {validationErrors.appointmentDate && (
              <p className="text-red-500 text-[10px]">
                {validationErrors.appointmentDate}
              </p>
            )}
          </div>

          <div className="w-full  md:w-1/3">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="notes"
            >
              Note:
            </label>
            <input
              className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
              focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
              type="text"
              id="note"
              name="note"
              placeholder="Enter Note"
              value={formData.note}
              onChange={handleChange}
              min={currentTime}
            />
          </div>
        </div>
        {/* <div className="lg:w-1/3 w-full">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email:
            </label>
            <input
              className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
              focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
              type="email"
              id="email"
              name="email"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleChange}
            />
          </div> */}

        {/* <div className="mb-4 flex md:flex-row justify-center items-center gap-4">

        </div> */}
        <h1 className="text-lg font-bold mb-4">Required Serivice</h1>
        {formData?.selectedServices?.map((service, index) => (
          <div key={index}>
            <div className="mb-4 flex flex-wrap justify-start items-center gap-4">
              <div className="md:w-[35%] w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor={`service-${index}`}
                >
                  Select service
                </label>
                <InvoiceDropdown
                  label="Select Service"
                  items={servicesArr?.map(ele => ({ id: ele?._id, name: ele?.name })) || []}
                  onSelect={(selectedService) => handleServiceChange({ target: { value: selectedService.id } }, index, "service")}
                  selectedItem={servicesArr?.find(s => s._id === service?.serviceId)?.name || "Select"}
                  itemName={`service-${index}`}
                  className="text-[#132c4a] border-0 placeholder-[#718092] bg-white rounded text-sm shadow 
             focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                />
              </div>

              <div className="md:w-[35%] w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor={`staff-${index}`}
                >
                  Select Staff
                </label>
                <InvoiceDropdown
                  label="Select Staff"
                  items={staffArr?.map(ele => ({ id: ele?._id, name: ele?.userId?.firstName })) || []}
                  onSelect={(selectedStaff) => handleServiceChange({ target: { value: selectedStaff.id } }, index, "staff")}
                  selectedItem={staffArr?.find(s => s._id === service?.staffId)?.userId?.firstName || "Select"}
                  itemName={`staff-${index}`}
                  className="border-0 placeholder-[#718092] bg-white rounded text-sm shadow 
             focus:outline-none focus:ring-1 ring-primaryColor text-black w-full ease-linear transition-all duration-150"
                />
              </div>

              <div className="md:w-[20%] w-full">
                <div className="flex items-center justify-center gap-2">
                  <img
                    src={Plus}
                    alt="Add"
                    onClick={addServiceField}
                    className="cursor- w-8 h-8"
                  />
                  <img
                    src={minus}
                    alt="Remove"
                    onClick={() => removeServiceField(index)}
                    className="cursor-pointer w-8 h-8"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        {appointment && Object.keys(appointment).length !== 0 && (
          <div className="lg:w-1/3 w-full">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="notes"
            >
              Reschedule Reason:
            </label>
            <textarea
              className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
              focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
              type="text"
              id="reason"
              name="reason"
              placeholder="Enter Reason"
              value={formData?.reason}
              onChange={handleChange}
            />
          </div>
        )}

        <div className="flex justify-between mt-4 items-center">
          <div className="p-2 flex gap-4">
            <span className="text-xl font-semibold">Quick Inquiry</span>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => handleToggle("quickInquiry")}
            >
              <div
                className={`relative w-8 h-4 rounded-full transition duration-300 ease-in-out ${checked.quickInquiry ? "bg-primaryColor" : "bg-gray-400"
                  }`}
              >
                <div
                  className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${checked.quickInquiry ? "translate-x-4" : "translate-x-0"
                    }`}
                >
                  <IoIosRadioButtonOff
                    size={22}
                    className={`text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <button
              onClick={handleSubmit}
              disabled={loading}
              className="bg-primaryColor text-white py-2 px-4 rounded-md hover:bg-primaryHoverColor focus:outline-none focus:ring-2 focus:ring-primaryColor"
            >
              {loading ? (
                <div className=" border-t-4 h-8 w-8 rounded-full animate-spin border-dashed border-white"></div>
              ) : (
                <span>
                  {!appointment || Object?.keys(appointment)?.length === 0
                    ? "Create"
                    : "Update"}
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AppointmentModal;
