import React from "react";
import AssignRole from "./AssignRole";
import CreateRoles from "./CreateRoles";

const PermissionScreen = ({
  isSelected,
  setResults,
  setStaff,
  setIsSelected,
  selectedCustomRole,
  fetchCustomerRoles,
  staff,
  customerRoles,
  fetchStaffData,
}) => {
  const classes = {
    active:
      "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md text-[#FF782D] bg-[#fff] hover:rounded-tl-md cursor-pointer transition ease-in-out duration-300 transform",
    inactive:
      "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md  text-[#fff] bg-transparent hover:bg-[#fff] hover:text-gray-900 hover:rounded-tl-md cursor-pointer transition ease-in-out duration-300 transform",
  };

  return (
    <React.Fragment>
      <div className="mx-auto">
        <div className="scroll-container w-[310px] overflow-x-auto inset-x-0 rounded-t-md bg-[#C0C0C0]">
          <div className="min-w-ful leading-normal">
            <div className="flex justify-between items-center">
              <div
                onClick={() => {
                  setResults([]);
                  setIsSelected("createRole");
                }}
                className={
                  isSelected === "createRole"
                    ? classes.active
                    : classes.inactive
                }
              >
                Create Role
              </div>
              <div
                onClick={() => {
                  setResults([]);
                  setIsSelected("assignRole");
                }}
                className={
                  isSelected === "assignRole"
                    ? classes.active
                    : classes.inactive
                }
              >
                Assign Role
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isSelected === "createRole" && (
        <CreateRole
          fetchCustomerRoles={fetchCustomerRoles}
          selectedCustomRole={selectedCustomRole}
        />
      )} */}

      {isSelected === "createRole" && (
        <CreateRoles
          fetchCustomerRoles={fetchCustomerRoles}
          selectedCustomRole={selectedCustomRole}
        />
      )}
      {/* {isSelected === "editRole"
                && <EditRole
                    selectedCustomRole={selectedCustomRole}
                    fetchCustomerRoles={fetchCustomerRoles} />} */}
      {isSelected === "assignRole" && (
        <AssignRole
          staff={staff}
          customerRoles={customerRoles}
          setStaff={setStaff}
          fetchStaffData={fetchStaffData}
        />
      )}
    </React.Fragment>
  );
};

export default PermissionScreen;
