import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Issue from '../../Components/ProductDetail/Issue';
import Consuptions from '../../Components/ProductDetail/Consuptions';
import Stock from '../../Components/ProductDetail/Stock';
import StockHistory from '../../Components/ProductDetail/StockHistory';
import { IoMdArrowRoundBack } from "react-icons/io";

function ProductDetail() {
    const params = useParams();
    const navigate = useNavigate();
    const main = useSelector((state) => state?.main);
    const product = main?.productList?.find((ele) => ele?._id === params?.id);

    const [activeConsumableButton, setActiveConsumableButton] = useState('ISSUE');
    const [activeRetailButton, setActiveRetailButton] = useState('STOCK');

    const handleRetailButtonClick = (button) => {
        setActiveRetailButton(button);
    };

    return (
        <div>
            <div className="flex items-center gap-2 py-2">
                <IoMdArrowRoundBack
                    size={25}
                    className="mt-3 cursor-pointer"
                    onClick={() => navigate("/admin/products")}
                />
                <div className=' text-xl font-bold mt-3'>
                    <h1>{product?.type === 'retail' && "Retail Product"}</h1>
                    <h1>{product?.type === 'consumable' && "Consumable Product"}</h1>
                </div>
            </div>

            <div className='border p-4 mt-3 bg-white flex gap-4'>
                <img
                    src={product?.url} alt="Product image"
                    className='w-[300px] border'
                />
                <div>
                    <div className='text-gray-500 grid grid-cols-1 gap-4'>
                        <h1>Product name : {product?.name}</h1>
                        <h1>Product Stock : {product?.quantity}</h1>
                        <h1>Product Brand : {product?.brand}</h1>
                        {
                            product?.type === 'retail' &&
                            <h1>Product Weight : {product?.volume} ml</h1>
                        }
                        {
                            product?.type === 'consumable' &&
                            <h1>Product Weight : {product?.totalVolume} ml</h1>
                        }
                    </div>
                </div>
            </div>
            <div>
                {
                    product?.type === 'retail' &&
                    <div>
                        <div className='border bg-white p-2 mt-3 grid grid-cols-4 items-center justify-between'>
                            <button
                                className={`py-1 transition-colors duration-300 ${activeRetailButton === 'STOCK' && 'bg-orange-500 text-white'}`}
                                onClick={() => handleRetailButtonClick('STOCK')}
                            >
                                STOCK
                            </button>
                            <button
                                className={`py-1 transition-colors duration-300 ${activeRetailButton === 'STOCK HISTORY' && 'bg-orange-500 text-white'}`}
                                onClick={() => handleRetailButtonClick('STOCK HISTORY')}
                            >
                                STOCK HISTORY
                            </button>
                        </div>

                        {
                            activeRetailButton === 'STOCK' &&
                            <div>
                                <Stock data={product} />
                            </div>
                        }
                        {
                            activeRetailButton === 'STOCK HISTORY' &&
                            <div>
                                <StockHistory />
                            </div>
                        }
                    </div>
                }
                {
                    product?.type === 'consumable' &&
                    <div>
                        <div className='border bg-white p-2 mt-3 grid grid-cols-4 items-center justify-between'>
                            <button
                                className={`py-1 transition-colors duration-300 ${activeConsumableButton === 'ISSUE' && 'bg-orange-500 text-white'}`}
                                onClick={() => setActiveConsumableButton('ISSUE')}
                            >
                                ISSUE
                            </button>
                            <button
                                className={`py-1 transition-colors duration-300 ${activeConsumableButton === 'CONSUMPTION' && 'bg-orange-500 text-white'}`}
                                onClick={() => setActiveConsumableButton('CONSUMPTION')}
                            >
                                CONSUMPTION
                            </button>
                            {/* <button
                                className={`py-1 transition-colors duration-300 ${activeConsumableButton === 'STOCK' && 'bg-orange-500 text-white'}`}
                                onClick={() => setActiveConsumableButton('STOCK')}
                            >
                                STOCK
                            </button> */}
                            <button
                                className={`py-1 transition-colors duration-300 ${activeConsumableButton === 'STOCK HISTORY' && 'bg-orange-500 text-white'}`}
                                onClick={() => setActiveConsumableButton('STOCK HISTORY')}
                            >
                                STOCK HISTORY
                            </button>
                        </div>
                        <div className='bg-white p-2'>
                            {
                                activeConsumableButton === 'ISSUE' &&
                                <div>
                                    <Issue data={product} />
                                </div>
                            }
                            {
                                activeConsumableButton === 'CONSUMPTION' &&
                                <div>
                                    <Consuptions />
                                </div>
                            }
                            {/* {
                                activeConsumableButton === 'STOCK' &&
                                <div>
                                    <Stock data={product} />
                                </div>
                            } */}
                            {
                                activeConsumableButton === 'STOCK HISTORY' &&
                                <div>
                                    <StockHistory />
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default ProductDetail;
