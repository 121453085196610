// Helper function to add a service field
import axios from "axios";
import { tostifyErr } from "../../../Screens/Common/tostifyMessages";
export const addServiceField = (formData, setFormData) => {
  let disableAdd = false;

  formData?.requiredServices?.forEach((service) => {
    if (!service?.serviceId || !service?.staff) {
      disableAdd = true;
    }
  });

  if (disableAdd) {
    return;
  }

  setFormData({
    ...formData,
    requiredServices: [
      ...formData.requiredServices,
      {
        serviceName: "",
        duration: "",
        staff: "",
        discount: "",
        serviceId: "",
        price: "",
        seat: "",
        quantity: "",
      },
    ],
  });
};

// Helper function to remove a service field
export const removeServiceField = (formData, setFormData, index) => {
  if (formData?.requiredServices?.length === 1) {
    return;
  }
  const updatedServices = [...formData.requiredServices];
  updatedServices.splice(index, 1);
  setFormData({
    ...formData,
    requiredServices: updatedServices,
  });
};

export const addProductField = (formData, setFormData) => {
  setFormData({
    ...formData,
    requiredProducts: [
      ...formData.requiredProducts,
      { productName: "", price: 0, staff: "", discount: "", quantity: "" },
    ],
  });
};

// Helper function to remove a product field
export const removeProductField = (formData, setFormData, index) => {
  if (formData?.requiredProducts?.length === 1) {
    return;
  }
  const updatedProducts = [...formData.requiredProducts];
  updatedProducts.splice(index, 1);
  setFormData({
    ...formData,
    requiredProducts: updatedProducts,
  });
};

// Helper function to handle checkbox change
export const handleCheckboxChange = (
  e,
  setShowDateOfBirth,
  setShowDateOfAnniversary
) => {
  const { name, checked } = e.target;
  if (name === "showDateOfBirth") {
    setShowDateOfBirth(checked);
  } else if (name === "showDateOfAnniversary") {
    setShowDateOfAnniversary(checked);
  }
};

// Helper function to handle membership deletion
export const handleDeleteMembership = async (
  index,
  storedDraftBill,
  client,
  addMember,
  setIsLoading,
  setClient,
  dispatch,
  storeClient,
  setAddMember
) => {
  if (storedDraftBill && Object.keys(storedDraftBill).length > 0) {
    setIsLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/customer/removeMembership`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
          },
          params: {
            customerId: client?._id,
            membershipId: addMember?.[0]?._id,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to remove membership");
      }

      dispatch(storeClient(response?.data?.data));
      setClient(response?.data?.data);
      const updatedAddMember = [...addMember];
      updatedAddMember.splice(index, 1);
      setAddMember(updatedAddMember);
      setIsLoading(false);
    } catch (error) {
      console.error("Error removing membership:", error);
      setIsLoading(false);
      return;
    }
  } else {
    const updatedAddMember = [...addMember];
    updatedAddMember.splice(index, 1);
    setAddMember(updatedAddMember);
  }
};

// Helper function to handle gender selection
export const handleGenderSelect = (value, setFormData) => {
  setFormData((prev) => ({
    ...prev,
    gender: value?.toLowerCase(),
  }));
};

// productHelpers.js

export const handleProductFieldChange = (
  e,
  index,
  fieldName,
  formData,
  setFormData,
  products
) => {
  const { value } = e.target;
  const updatedProducts = [...formData.requiredProducts];
  const currentProduct = updatedProducts[index];

  // Helper function to calculate price
  const calculatePrice = (quantity, itemPrice) => quantity * itemPrice;

  // Helper function to handle discount logic
  const handleDiscountChange = (discount) => {
    if (!currentProduct.discountType) {
      alert("Please select a Discount Type First");
      return { discount: 0 };
    }

    discount = parseFloat(discount);
    if (isNaN(discount) || discount < 0) {
      return {
        price: calculatePrice(
          currentProduct.quantity,
          currentProduct.itemPerPrice
        ),
        discountText: "",
        discount: 0,
      };
    }

    const originalPrice = calculatePrice(
      currentProduct.quantity,
      currentProduct.itemPerPrice
    );
    let discountAmount, newPrice;

    if (currentProduct.discountType === "flat") {
      if (discount >= originalPrice) {
        alert("Invalid Discount");
        return null;
      }
      discountAmount = discount;
      newPrice = originalPrice - discount;
    } else if (currentProduct.discountType === "percentage") {
      if (discount >= 100) {
        alert("Invalid Discount");
        return null;
      }
      discountAmount = Math.ceil((originalPrice * discount) / 100);
      newPrice = originalPrice - discountAmount;
    }

    return {
      price: newPrice,
      discountText: `Price After Discount ${Number(newPrice)?.toFixed(2)}.`,
      discount,
    };
  };

  // Update product fields based on the fieldName
  switch (fieldName) {
    case "productName":
      const selectedProduct = products?.find((p) => p?.name === value);
      if (selectedProduct) {
        updatedProducts[index] = {
          ...currentProduct,
          productName: value,
          price: selectedProduct.costPriceMRP,
          itemPerPrice: selectedProduct.costPriceMRP,
          productId: selectedProduct._id,
          quantity: 1,
        };
      } else {
        updatedProducts[index] = {
          ...currentProduct,
          productName: "",
          price: 0,
          itemPerPrice: 0,
          productId: "",
          quantity: 0,
        };
      }
      break;

    case "quantity":
      const quantity = parseInt(value);
      if (!isNaN(quantity)) {
        const newPrice = calculatePrice(quantity, currentProduct.itemPerPrice);
        updatedProducts[index] = {
          ...currentProduct,
          quantity,
          price: newPrice,
          discount: 0,
          discountText: "",
        };
      }
      break;

    case "discountType":
      updatedProducts[index] = {
        ...currentProduct,
        discountType: value,
        discount: 0,
        price: calculatePrice(
          currentProduct.quantity,
          currentProduct.itemPerPrice
        ),
        discountText: "",
      };
      break;

    case "discount":
      const discountUpdate = handleDiscountChange(value);
      if (discountUpdate) {
        updatedProducts[index] = {
          ...currentProduct,
          ...discountUpdate,
        };
      }
      break;

    default:
      updatedProducts[index] = { ...currentProduct, [fieldName]: value };
  }

  // Update the state with modified products array
  setFormData((prevFormData) => ({
    ...prevFormData,
    requiredProducts: updatedProducts,
  }));
};

export const fetchClient = async (phone, branch) => {
  if (!phone) return;

  try {
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/customer/singleCustomer?phone=${phone}&branch=${branch?._id}`,
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.request(config);
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching client:", error);
    throw error;
  }
};

export const fetchMembershipInfo = async (id) => {
  if (!id) return;

  try {
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/membership-package/get-membership/${id}`,
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.request(config);
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching Membership:", error);
    throw error;
  }
};

export function combineServices(clientServices, addMemberServices) {
  const combinedServices = {};

  // Process client services
  if (clientServices) {
    Object.keys(clientServices)?.forEach((serviceId) => {
      combinedServices[serviceId] = clientServices[serviceId];
    });
  }

  // Process addMember services
  if (addMemberServices) {
    addMemberServices?.forEach((service) => {
      const serviceId = service.serviceId;
      const quantity = service.quantity;

      if (combinedServices[serviceId]) {
        // If the service already exists, add the quantities
        combinedServices[serviceId] += quantity;
      } else {
        // Otherwise, set the quantity directly
        combinedServices[serviceId] = quantity;
      }
    });
  }

  return combinedServices;
}

export const handleCreateMembershipRevenue = async ({
  customerId,
  adminId,
  membershipPackageId,
  price,
  packagePrice,
  organizationId,
  startDate,
  expireDate,
}) => {
  try {
    const data = JSON.stringify({
      customerId,
      adminId,
      membershipPackageId,
      price,
      packagePrice,
      organizationId,
      date: startDate,
      expireDate,
    });
    const token = "Bearer " + localStorage.getItem("refresh_token");

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/membership-revenue/add`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error(error);
    tostifyErr(error?.response?.data?.message || "An error occurred");
    throw error;
  }
};

export const updateAppointment = async (appointment) => {
  const obj = {
    status: "completed",
  };
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/appointment/update/${appointment?._id}`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: obj,
  };

  try {
    const response = await axios.request(config);
    console.log("Update successful", response?.data?.data?.updateAppointment);
    return response?.data?.data?.updateAppointment;
  } catch (error) {
    console.error("Error updating appointment:", error);
    tostifyErr(error?.response?.data?.message);
    throw error;
  }
};

export const applyCouponAndCreateHistory = async ({
  couponCode,
  customerId,
  totalAmount,
  branch,
}) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/coupon/apply-coupon`;
    const token = "Bearer " + localStorage.getItem("refresh_token");

    const payload = {
      couponCode,
      customerId,
      amount: totalAmount,
      branch,
    };

    const response = await axios.post(apiUrl, payload, {
      headers: {
        Authorization: token,
      },
    });

    // Handle success
    console.log("Coupon applied successfully:", response.data);
    return response.data;
  } catch (error) {
    // Handle errors
    if (error.response) {
      console.error("Error applying coupon:", error.response.data.message);
    } else {
      console.error("Network error:", error.message);
    }
    throw error;
  }
};

export const createOrUpdateCouponHistory = async ({
  couponCode,
  customerId,
  discountAmount,
  branch,
}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/coupon/create-coupon-history`,
      {
        couponCode,
        customerId,
        discountAmount,
        branch,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("refresh_token"),
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    console.error(
      "Error updating coupon history:",
      error.response?.data?.message || error.message
    );
    return {
      success: false,
      message:
        error.response?.data?.message || "Failed to update coupon history",
    };
  }
};
