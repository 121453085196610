import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaDownload, FaGift } from "react-icons/fa";
import { HiOutlineMailOpen } from "react-icons/hi";
import { MdModeEditOutline, MdOutlineTextsms } from "react-icons/md";
import { SlPrinter } from "react-icons/sl";
import { GrView } from "react-icons/gr";
import kaya from "../../../Assets/Frame 427321207.png";
import PaymentEditModal from "./PaymentEditModal";
import { useDispatch, useSelector } from "react-redux";
import kayaJharsuguda from "../../../Assets/KayaJharsuguda-removebg-preview.png";
import InvoiceViewDeleteModal from "./InvoiceViewDeleteModal";
import { clearClient, clearDraftBill, clearNewBill, storeClient, storeDraftBill } from "../../../state-management/main/actions";
import html2pdf from "html2pdf.js";
import NewLoader from "../../../Screens/Common/NewLoader";
import { updateBilling, updateCustomerWallet } from "../../../Screens/Common/initData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  py: 1,
  px: 4,

  // "@media (max-width: 768px)": {
  //   width: "80%",
  //   height: "70%",
  // },

  // "@media (max-width: 320px)": {
  //   width: "90%",
  //   height: "90%",
  // },

  // "@media (min-width: 1024px)": {
  //   width: "65%",
  //   height: "80%",
  // },
};

const InvoiceViewModal = ({
  singleInvoice,
  setViewModal,
  viewModal,
  id,
  setId,
  getBillData,
  FetchBillingData
}) => {
  const [previewOption, setPreviewOption] = useState("pdfview");
  const [openEditPayment, setOpenEditPayment] = useState(false);
  const [isWalletApplied, setIsWalletApplied] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [isEdited, setIsEdited] = useState(false);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [applyBalance, setApplyBalance] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const branch = useSelector((state) => state?.main?.branch) || "";
  const paymentStatus = singleInvoice?.paymentStatus;
  const printRef = useRef(null);
  const draftBill = useSelector(state => state?.main?.draftBill);
  const clientDuringBill = useSelector(state => state?.main?.clientDuringBill);

  const dispatch = useDispatch();

  const handlePreviewOptionChange = (option) => {
    setPreviewOption(option);
  };

  const handleWalletEdit = async (clientDuringBill, draftBill) => {
    try {
      setIsLoading(true);
      const newWalletBalance = clientDuringBill?.walletBalance + draftBill?.walletApplied;
      const newDueAmount = draftBill?.dueAmount + draftBill?.walletApplied;

      const updatedClient = await updateCustomerWallet(newWalletBalance, clientDuringBill._id, newDueAmount);

      if (!updatedClient) {
        throw new Error("Failed to update customer wallet");
      }

      const filteredPaymentMethods = draftBill?.paymentMethods?.filter(method => method.payment !== "WalletBalance");

      const newPaidAmount = draftBill?.paidAmount - draftBill?.walletApplied;

      const updatedBill = await updateBilling(draftBill._id, {
        walletApplied: 0,
        paymentMethods: filteredPaymentMethods,
        paidAmount: newPaidAmount,
        dueAmount: newDueAmount
      });

      if (!updatedBill) {
        throw new Error("Failed to update billing information");
      }

      dispatch(storeDraftBill(updatedBill));
      dispatch(storeClient(updatedClient));
      setApplyBalance(true);
      setIsWalletApplied(false);
    } catch (error) {
      console.error("Error in handleWalletEdit:", error);
      alert("An error occurred while editing the wallet. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompleteButtonClick = () => {
    setApplyBalance(true)
    setIsWalletApplied(false);
    setOpenEditPayment(true);
  };

  const handlePrint = () => {
    const content = renderPreviewContent();
    const printWindow = window.open("", "_blank");
    if (previewOption === "pdfview") {
      // For thermal view, use the content as is
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>${content}</body>
        </html>
      `);
    } else {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              body {
                margin:0;
                padding: 0;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
              }
              @media print {
                body {
                  width: 90%;
                  height: 80%;
                }
                @page {
                  size: auto;
                  margin: 5mm;
                }
                table {
                  border-collapse: collapse;
                  width: 100%;
                }
                th, td {
                  padding: 2px; /* Reduce cell padding */
                  text-align: left;
                  border: 1px solid #ddd;
                }
                .header {
                  margin-bottom: 10px; /* Reduce header margin */
                }
                .header img {
                  max-width: 60px; /* Reduce logo size */
                }
                h1, h2, h3 {
                  margin: 5px 0; /* Reduce heading margins */
                }
                p {
                  margin: 2px 0; /* Reduce paragraph margins */
                }
                .terms {
                  margin-top: 10px; /* Reduce terms margin */
                }
                .terms ul {
                  padding-left: 15px; /* Reduce list padding */
                }
              }
            </style>
          </head>
          <body>${content}</body>
        </html>
      `);
    }
    printWindow.document.close();
    printWindow.focus();
    printWindow.onload = function () {
      printWindow.print();
      printWindow.onafterprint = function () {
        printWindow.close();
      };
    };
  };

  const totalAmount =
    draftBill?.products?.reduce(
      (acc, product) => acc + (product?.price || 0),
      0
    ) +
    (draftBill?.servicesIncludedInMembership?.length
      ? draftBill?.serviceBilledOn?.reduce(
        (acc, service) => acc + (service?.price || 0),
        0
      )
      : draftBill?.services?.reduce(
        (acc, service) => acc + (service?.price || 0),
        0
      )) +
    Number(draftBill?.membershipIds?.[0]?.price || 0);

  const renderPreviewContent = () => {
    const branchName = branch?.name || "";
    const branchLocation = branch?.address || "";
    const branchPhone = branch?.primaryPhone || "";
    const branchPic = branch?.url || kayaJharsuguda;
    let indexvalue = 1;


    // const { billingInfo } = singleInvoice;

    const customerName = draftBill?.billingInfo?.name || "";
    const customerPhone = draftBill?.billingInfo?.phone || "";

    const invoiceNumber = draftBill?.invoiceNumber || "";
    if (previewOption === "pdfview") {
      const pdfContent = `
      <!DOCTYPE html>
      <html lang="en">

      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap" rel="stylesheet">
        <title>Invoice</title>
        <style>
        .dm-serif-display-regular {
        font-family: "DM Serif Display", serif;
        font-weight: 400;
        font-style: normal;
      }

      .dm-serif-display-regular-italic {
        font-family: "DM Serif Display", serif;
        font-weight: 400;
        font-style: italic;
      }

          body {
            margin: 0;
            padding: 0;
            line-height: 1.4;
          }

          .container {
            max-width: 380px;
            margin: 0 auto;
            padding: 10px;
          }

          .header {
            text-align: center;
            font-weight: 900;
            margin-bottom: 10px;
            padding-bottom: 5px;
          }
        .header h3{
        margin:0;
          }
        .header h4{
          margin:0;
          font-size:25px;
          }
          .logo {
            margin-bottom: 5px;
          }

          .address {
            margin: 5px 0;
          }


          .tables td{
          text-align:left;
          }
            .table {
            width: 100%; 
            margin-bottom: 10px;
            font-weight: 900;
            margin: 0 auto;
          }

          .table th {
            text-align: left;
            padding: 0;
          }

          .table td {
            padding: 0;
            text-align: right;
          }

          .table td:first-child {
            text-align: left;
          }

          .total {
            text-align: right;
            font-weight: bold;
          }

          .footer {
            text-align: center;
            font-weight: 900;
            margin-top: 10px;
            padding-top: 5px;
          }
        .tabletopDiv {
          display: flex;
          justify-content: space-between;
          align-items: center;
      }
          .tabletopDiv h2{
          font-size:16px;
          margin:1px 0 ;
          }
          .tabletopDiv h4{
          font-size:16px;
          margin:1px 0;
            width: 40%;
          text-align: left; 
          }

        </style>
      </head>

      <body>
      <div class="container">
          <div class="header">
            <img src='${branchPic}' alt="Plus" width='70' class='logo' />
            <h3>${branchName}</h3>
            <p class='address'>${branchLocation}</p>
            <p class='address'>Contact: ${branchPhone}</p>
            <h4>TAX INVOICE</h4>
          </div>
          <hr style="border: 1px dashed black;  margin-top:8px; margin:0;" />
          <hr style="border: 1px dashed black;  margin-top:3px;" />
      
        <div class="tabletop">
            <div class="tabletopDiv">
            <h2>NAME</h2>
            <h4 style="text-transform: uppercase;"">:${customerName}</h4>
            </div>
            <div class="tabletopDiv">
            <h2>MOBILE</h2>
            <h4>:${customerPhone}</h4>
            </div>
            <div class="tabletopDiv">
            <h2>INVOICE</h2>
            <h4>:${invoiceNumber}</h4>
            </div>
            <div class="tabletopDiv">
            <h2>DATE</h2>
            <h4>:${new Date(draftBill?.date)?.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}</h4>
            </div>
        </div> 




          <hr style="border: 1px dashed black; margin-top:8px; margin-bottom: 0;" />
          <hr style="border: 1px dashed black;  margin-top:3px;"/>
          <table class="table">
      <thead>
        <tr>
          <th style="padding-right:60px;">
            <div style="display: flex; flex-direction: column; align-items: flex-start;">
              <p style="margin: 0;">NAME</p>
              <p style="margin: 0;">STAFF</p>
            </div>
          </th>
          <th style="padding-left:0;; font-size:12px; text-align: center;   vertical-align: bottom;">PRICE</th>
          <th style="padding-right:2px; font-size:12px; text-align: center; vertical-align: bottom;">DISCOUNT</th>
          <th style="padding-right:0; font-size:12px; text-align: center; vertical-align: bottom;">QTY</th>
          <th style="padding-right:0; font-size:12px; text-align: center; vertical-align: bottom;">TOTAL</th>
        </tr>
      </thead>
        <tbody>
          ${draftBill?.services
          ?.map?.(
            (ele, ind) => `
            <tr key="service-${ind}">
              <td style="font-size:14px; display:flex; flex-direction:column;">
              <p style="margin:0;">${ele?.serviceName}</p>
              <p style="margin:0; color:gray;">(by ${ele?.staffName})</p>
            </td>
              <td>${ele?.itemPerPrice}</td>
              <td style="text-align: center;">
      ${ele?.discountType === "percentage"
                ? (ele?.discount || 0) + (ele?.discount ? " %" : "")
                : (ele?.discount || 0)}
      </td>
              <td style="text-align: center;">${ele?.quantity}</td>
              <td>₹${ele?.price}/-</td>
            </tr>`
          )
          .join("")}
          ${draftBill?.products
          ?.map?.(
            (ele, ind) => `
            <tr key="product-${ind}">
              <td style="font-size:14px">${ele?.productName}</td>
              <td>${ele?.itemPerPrice}</td>
              <td style="text-align: center;">${ele?.discountType === "percentage"
                ? ele?.discount + " %"
                : ele?.discount || 0
              }</td>
              <td style="text-align: center;>${ele?.quantity}</td>
              <td style="text-align:center;">₹${ele?.price}/-</td>
            </tr>`
          )
          .join("")}
          ${draftBill?.membershipIds
          ?.map?.(
            (ele, ind) => `
            <tr key="membership-${ind}">
              <td>${ele?.packageName}</td>
              <td>${ele?.price}</td>
              <td style="text-align:center;">-</td>
              <td style="text-align: center;">${ind + 1}</td>
              <td >₹${ele?.price}/-</td>
            </tr>`
          )
          .join("")}
      
          ${draftBill?.servicesIncludedInMembership?.length
            ? `
              <tr>
                <td>Free Services</td>
                <td colspan="4" style="text-align: left;">
                  <table style="width: 100%;">
                    ${draftBill.servicesIncludedInMembership
                      .map((service, ind) => {
                        if (!service || !service.serviceId) {
                          console.warn("Invalid service entry:", service);
                          return "";
                        }
          
                        const quantity = draftBill?.freeServices?.[service?.serviceId] || 0;
                        const totalPrice = service?.itemPerPrice * quantity || 0;
          
                        return `
                          <tr key="free-service-${ind}">
                            <td>${service?.serviceName || "Unknown Service"}</td>
                            <td>x ${quantity}</td>
                            <td>₹${totalPrice}</td>
                          </tr>
                        `;
                      })
                      .join("")}
                  </table>
                </td>
              </tr>
            `
            : ""}        
          
        </tbody>
      </table>
      <hr style="border: 1px dashed black; margin-top:8px;  margin-bottom: 0;" />
          <hr style="border: 1px dashed black; margin-top:3px;" />
          <table class="table">
            <tr>
              <th>SUBTOTAL</th>
              <td>₹${totalAmount}/-</td>
            </tr>
            <tr>
              <th>NET</th>
              <td>₹${totalAmount}/-</td>
            </tr>
            <tr>
              <th>GRAND TOTAL</th>
              <td>₹${totalAmount}/-</td>
            </tr>
          </table>
          <hr style="border: 1px dashed black; margin-top:8px; margin-bottom: 0;" />
          <hr style="border: 1px dashed black;  margin-top:3px;" />
          <table class="table">
            <tr>
              <th>Payments</th>
              </tr>
              ${isEdited
          ? draftBill?.paymentMethods?.length > 0
            ? draftBill.paymentMethods
              .map(
                (method, index) => `
                      <tr key=${index}>
                        <td>${method?.payment || ""}</td>
                        <td>₹${method?.amount || 0}</td>
                      </tr>
                    `
              )
              .join("")
            : `
                      <tr>
                        <td colspan="2">No payment methods available</td>
                      </tr>
                    `
          : draftBill?.paymentMethods?.length > 0
            ? draftBill.paymentMethods
              .map(
                (method, index) => `
                      <tr key=${index}>
                        <td>${method?.payment || ""}</td>
                        <td>₹${method?.amount || 0}</td>
                      </tr>
                    `
              )
              .join("")
            : `
                      <tr>
                        <td colspan="2">No payment methods available</td>
                      </tr>
                    `
        }
              <tr>
              
              <th>Paid</th>
              <td>₹${isEdited
          ? draftBill?.paymentMethods?.reduce(
            (acc, cur) => acc + cur.amount,
            0
          )
          : draftBill?.paymentMethods?.reduce(
            (acc, cur) => acc + cur.amount,
            0
          )
        }</td>
            </tr>
          </table>
          <hr style="border: 1px dashed black; margin-top:8px; margin-bottom: 0;" />
          <hr style="border: 1px dashed black;  margin-top:3px;" />
          <div class="footer">
            <p style="margin:0;">Please visit again!</p>
            <p style="margin-top:2px;">Generated By: at ${new Date().toISOString().slice(0, 10)}</p>
          </div>
        </div>
      </body>

      </html>   
      `;

      return pdfContent;
    } else {
      const tableContent = `
    <div style="width: 100%;">
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
          body {
            font-family: Nunito;
            margin: 0;
            padding: 20px;
          }
          .invoice {
            width: 700px;
            margin: 0 auto;
            border: 1px solid #ccc;
            padding: 20px;
            box-sizing: border-box;
          }
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .header img {
            max-width: 100px;
          }
          .from, .to {
            width: 50%;
            box-sizing: border-box;
          }
          .from {
            float: left;
            text-align: left;
          }
          .to {
            float: right;
            text-align: right;
          }
          .clearfix::after {
            content: "";
            clear: both;
            display: table;
          }
            .container{
            width:100%;
            overflow-x:auto;
            }
            .container::-webkit-scrollbar{
            display:none;
            }
          table {
              width: 100%;
              table-layout: fixed;
              border-collapse: separate;
              border-spacing: 0;
              margin-top: 20px;
              border: 1px solid black;
            }
            th, td {
              padding: 12px 8px;
              text-align: center;
              border-right: 1px solid black;
              border-bottom: 1px solid black;
              word-wrap: break-word;
            }
            th:last-child, td:last-child {
              border-right: none;
            }
            tr:last-child td {
              border-bottom: none;
            }
            th {
              background-color: #fe8740;
              color: #fff;
              font-weight: 700;
              text-transform: uppercase;
            }
            th:nth-child(1), td:nth-child(1) { width: 4%; }  /* Sl No */
            th:nth-child(2), td:nth-child(2) { width: 40%; } /* Services */
            th:nth-child(3), td:nth-child(3) { width: 6%; }  /* Qty */
            th:nth-child(4), td:nth-child(4) { width: 12%; } /* Amount */
            th:nth-child(5), td:nth-child(5) { width: 14%; } /* Discount */
            th:nth-child(6), td:nth-child(6) { width: 12%; } /* Total Amount */
            
            tr.total-row td {
              background-color: #e9ecef;
              font-weight: 700;
            }
          .total {
            text-align: right;
            margin-top: 20px;
          }
          .payment {
            margin-top: 20px;
          }
          .terms {
            margin-top: 20px;
            }
            tr.no-vertical-border td {
              border-top: none;
              border-bottom: none;
            }
            tr.no-vertical-border:last-child td {
              border-bottom: none;
            }
            tr.no-vertical-border:first-child td {
              border-top: none;
            }

             /* Styles for the payment summary table */
            .payment-summary-table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            
            .payment-summary-table td {
              padding: 8px;
              border: 1px solid black;
            }
            
            .payment-summary-table td:first-child {
              width: 70%; /* Make the first column wider */
              text-align: left;
            }
            
            .payment-summary-table td:last-child {
              width: 30%; /* Make the second column narrower */
              text-align: right;
            }

            /* Style for the header row */
            .payment-summary-table tr:first-child td {
              background-color: #fff;
              font-weight: 700;
            }
        </style>
          <div class="invoice">
            <div class="header">
              <img src='${branchPic}' alt="Kaya Spa">
              <div >
                <h2 style="text-align: right;">INVOICE</h2>
                <p style="text-align: right;">Invoice No. - ${invoiceNumber}</p>
              <p style="text-align: right;">
                      Date/Time - ${new Date(draftBill?.date)?.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}
        </p>

              </div>
            </div>
            <div class="clearfix">
              <div class="from">
                <h3>Invoice From:</h3>
                <p>${branchName}</p>
                <p>AT - ${branchLocation}</p>
                <p> ${branchPhone}</p>
              </div>
              <div class="to">
                <h3>Invoice To:</h3>
                <p>${draftBill?.billingInfo?.name ||
        customerName ||
        customerPhone ||
        ""
        }</p>
                <p> ${customerPhone || ""}</p>
              <span>Wallet Bal.: Rs.${clientDuringBill?.walletBalance}/-</span>
                <p>Loyalty Point Bal: 0.00/-</p>
              </div>
            </div>
            
        <div class="container">
            <table>
          <tr>
            <th>Sl</th>
            <th>Services</th>
            <th>QTY</th>
            <th>Amount</th>
            <th>Discount</th>
            <th>Total</th>
          </tr>

          ${draftBill?.services
          ?.map(
            (service, index) => `
                <tr key=${index}>
                  <td>${indexvalue++}</td>
                  <td>${service?.serviceName} (${service?.duration}) (${service?.staffName})</td>
                  <td>${service?.quantity}</td>
                  <td>₹${service?.itemPerPrice * service?.quantity}</td>
                  <td>${service?.discountType === "flat"
                ? service?.discount || 0
                : service?.discountType === "percentage"
                  ? (service?.discount || 0) + (service?.discount ? "%" : "")
                  : 0
              }</td>
                  <td>₹${service?.price}</td>
                </tr>
              `
          )
          .join("")}
          ${draftBill?.products
          ?.map(
            (product, index) => `
                <tr key=${index}>
                  <td>${indexvalue++}</td>
                  <td>${product?.productName}</td>
                  <td>${product?.quantity}</td>
                  <td>₹${product?.itemPerPrice * product?.quantity}</td>
                  <td>${product?.discountType === "flat"
                ? product?.discount
                : product?.discountType === "percentage"
                  ? product?.discount + "%"
                  : ""
              }</td>
                  <td>₹${product?.price}</td>
                </tr>
              `
          )
          .join("")}
          ${draftBill?.membershipIds && draftBill?.membershipIds.length > 0
          ? draftBill?.membershipIds
            .map(
              (member, index) => `
                  <tr key=${index}>
                    <td>${indexvalue++}</td>
                    <td>${member?.packageName || ""}</td>
                    <td>1</td>
                    <td>₹${member?.price || 0}</td>
                    <td>${member?.discount || 0}</td>
                    <td>₹${(member?.price || 0) - (member?.discount || 0)}</td>
                  </tr>
                `
            )
            .join("")
          : ""
        }
          ${Array(5)
          .fill("")
          .map(
            () => `
            <tr class="no-vertical-border">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          `
          )
          .join("")}
        </table>
        </div>

              <table class="payment-summary-table ">
                    <tr>
                      <td colspan="4" style="text-align: left;">Amount:</td>
                      <td colspan="2" style="text-align: right;">
                      ₹${totalAmount}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4" style="text-align: left;">Total (Rounded off)</td>
                      <td colspan="2" style="text-align: right;">
                      ₹${totalAmount}
                      </td>
                    </tr>
                        ${(() => {
          if (isEdited) {
            if (draftBill?.paymentMethods?.length > 0) {
              return draftBill.paymentMethods
                .map(
                  (method, index) => `
                                <tr key=${index} class="payment-method">
                                <td colspan="4" style="text-align: left;"> ${method?.payment || ""
                    }</td>
                                  <td colspan="2" style="text-align: right;">₹${method?.amount
                    }</td>
                                </div>
                              `
                )
                .join("");
            }
          } else {
            if (draftBill?.paymentMethods?.length > 0) {
              return draftBill.paymentMethods
                .map(
                  (method, index) => `
                                <tr key=${index} class="payment-method">
                                <td  colspan="4" style="text-align: left;"> ${method?.payment || ""
                    }</td>
                                  <td colspan="2" style="text-align: right;">₹${method?.amount
                    }</td>
                                </tr>
                              `
                )
                .join("");
            }
          }
          return "No payment methods available";
        })()}
                      </td>
            </table>

            <div class="terms">
              <h3>Terms &amp; Conditions</h3>
              <ul>
                <li>Money once paid shall not be refunded.</li>
                      <li>Services shall not be rendered beyond expiry or validity period.</li>
                      <li>Services are not transferable.</li>
              </ul>
            </div>
          </div>
        </div>
`;
      return tableContent;
    }
  };

  const handleDownload = () => {
    const content = renderPreviewContent();

    html2pdf()
      .from(content)
      .set({
        margin: 10,
        filename: "preview.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
      })
      .save();
  };

  const handleViewPdf = () => {
    const content = renderPreviewContent();
    setSelectedContent(content);
    setPdfViewerOpen(true);
  };

  const handleApplyWalletBalance = async () => {
    if (Number(walletBalance) > clientDuringBill?.walletBalance - draftBill?.walletApplied) {
      alert("Please enter less than the current wallet balance");
      return;
    }
    setIsLoading(true);
    try {
      const newClient = await updateCustomerWallet(clientDuringBill?.walletBalance - Number(walletBalance), clientDuringBill?._id, clientDuringBill?.dueAmount - Number(walletBalance));

      const previousPaymentMethods = draftBill?.paymentMethods;
      const paymentMethods = [
        ...previousPaymentMethods,
        {
          id: new Date().getTime(),
          payment: 'WalletBalance',
          amount: Number(walletBalance),
          paymentDate: new Date()?.toISOString()?.slice(0, 10)
        }
      ];

      const allPayment = paymentMethods?.reduce((acc, cur) => acc + cur?.amount, 0);

      const newDraftBill = await updateBilling(draftBill?._id, {
        paymentMethods,
        dueAmount: draftBill?.dueAmount - Number(walletBalance),
        paidAmount: draftBill?.paidAmount + Number(walletBalance),
        walletApplied: draftBill?.walletApplied + Number(walletBalance),
        paymentStatus: Number(totalAmount - allPayment) > 0 ? "due" : "paid"
      });

      if (newClient && newDraftBill) {
        dispatch(storeClient(newClient));
        dispatch(storeDraftBill(newDraftBill));
        setIsWalletApplied(true);
        setWalletBalance(0);
        setApplyBalance(false);
      }
    } catch (error) {
      console.error("Error applying wallet balance:", error);
      alert("An error occurred while applying the wallet balance. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={viewModal}
      // onClose={() => props.setViewModal(false)}
      >
        <Box sx={style} className="no-scrollbar">
          <div>
            <div className="btn-wrapper text-center">
              <span
                className="absolute top-4 right-8 text-3xl cursor-pointer"
                onClick={() => {
                  dispatch(clearNewBill());
                  setViewModal(false);
                  dispatch(clearDraftBill());
                  dispatch(clearClient());
                }}
              >
                x
              </span>
              <div className="text-2xl text-[#2b2f32] font-bold text-center">
                Invoice View
              </div>
            </div>

            <div className="flex flex-col justify-center mt-2">
              <div className="flex gap-6 justify-center items-center">
                <div className="flex flex-col">
                  <div className="flex flex-col justify-center items-center">
                    <img
                      src={branch?.url || kaya}
                      alt="Logo"
                      className="w-auto h-20"
                    />
                    {/* <p className="text-[18px] font-semibold capitalize">
                      {branch?.name}
                    </p> */}
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <p className="text-[18px] font-semibold">Wallet Balance</p>
                  <p className="text-[18px] font-semibold text-gray-400">
                    {clientDuringBill?.walletBalance || 0}
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <p className="text-[18px] font-semibold">Due Amount</p>
                  <p className="text-red-500 font-semibold text-[18px]">
                    {draftBill?.dueAmount || 0}
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <p className="text-[18px] font-semibold">Date</p>
                  <p className="text-[18px] font-semibold text-gray-400">
                    {draftBill?.date?.slice(0, 10)}
                  </p>
                </div>
              </div>

              <div className="flex justify-center items-center flex-wrap gap-2">
                <button
                  onClick={handleDownload}
                  className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2"
                >
                  Download
                  <FaDownload
                    size={15}
                    className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                  />
                </button>

                <button
                  onClick={handleViewPdf}
                  className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2"
                >
                  View
                  <GrView
                    size={15}
                    className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                  />
                </button>

                <button
                  className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2"
                  onClick={handlePrint}
                >
                  Print
                  <SlPrinter
                    size={15}
                    className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                  />
                </button>

                <button className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2">
                  Email
                  <HiOutlineMailOpen
                    size={15}
                    className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                  />
                </button>

                <button className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2">
                  Sms
                  <MdOutlineTextsms
                    size={15}
                    className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                  />
                </button>
              </div>
            </div>

            <div className="flex justify-center items-center flex-col mt-4">
              <div className="flex flex-col w-full">
                <div className="flex gap-4 justify-center items-center">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="webview"
                      name="previewOption"
                      value="webview"
                      onChange={(e) =>
                        handlePreviewOptionChange(e.target.value)
                      }
                      checked={previewOption === "webview"}
                      className="mr-2"
                    />
                    <label htmlFor="webview">Web View</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="pdfview"
                      name="previewOption"
                      value="pdfview"
                      onChange={(e) =>
                        handlePreviewOptionChange(e.target.value)
                      }
                      checked={previewOption === "pdfview"}
                      className="mr-2"
                    />
                    <label htmlFor="pdfview">Thermal View</label>
                  </div>
                </div>
                <div className="flex justify-center">
                  <iframe
                    ref={printRef}
                    title="Print Content"
                    className="w-full overflow-y-scroll no-scrollbar h-[300px]"
                    srcDoc={renderPreviewContent()}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-3 items-center md:mt-0 mt-3">
              <p className="font-bold text-[18px]">Total Price :</p>
              <p className="text-[18px] text-red-400 font-semibold">
                {isEdited ? draftBill?.finalPrice : draftBill?.finalPrice}
              </p>
            </div>
            <div className="flex gap-3 items-center md:mt-0 mt-3">
              <p className="font-bold text-[18px]"> Due Amount:</p>
              <p className="text-[18px] text-red-400 font-semibold">
                {isEdited ? draftBill?.dueAmount : draftBill?.dueAmount}
              </p>
            </div>

            <div className="flex justify-between items-center md:flex-row flex-col gap-2">
              <div className="flex gap-2 items-start">
                <button
                  onClick={() => setShowOptions(!showOptions)}
                  className="p-2 w-fit bg-primaryColor text-white rounded-full shadow-lg hover:bg-primaryHoverColor transition duration-300"
                >
                  <FaGift size={24} />
                </button>
                {showOptions &&
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2">
                      <input
                        type="checkbox"
                        name="walletBalance"
                        id="walletBalance"
                        value={applyBalance}
                        checked={applyBalance}
                        onChange={() => {
                          setApplyBalance((prev) => !prev);
                          setActiveTab("wallet");
                        }}
                      />
                      <span>Apply Wallet Balance</span>
                    </div>

                    <div className="grid grid-cols-2">

                      {applyBalance && (
                        <div className="flex gap-4 mt-2 w-full">
                          <div className="md:w-[60%] w-full flx flex-col gap-1">
                            <label className="text-red-500" htmlFor="applyBalance">
                              {walletBalance >
                                clientDuringBill?.walletBalance
                                ? "Insufficent Wallet Balance"
                                : ""}
                            </label>
                            <input
                              className={
                                walletBalance >
                                  clientDuringBill?.walletBalance
                                  ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                                  : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                              }
                              type="number"
                              placeholder="Apply Balance"
                              id="balance"
                              name="balance"
                              disabled={isWalletApplied}
                              value={walletBalance}
                              min={0}
                              onChange={(e) => setWalletBalance(e.target.value)}
                            />
                          </div>
                          <div
                            className={`md: w - [25 %] w - full ${walletBalance >
                              clientDuringBill?.walletBalance &&
                              "mt-6"
                              } `}
                          >
                            <button
                              disabled={
                                walletBalance >
                                clientDuringBill?.walletBalance ||
                                isWalletApplied
                              }
                              onClick={() => handleApplyWalletBalance()}
                              className="py-2 w-full flex items-center justify-center text-md px-4 font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor rounded-lg shadow-lg transition ease-in-out duration-300"
                            >
                              {isLoading ? (
                                <NewLoader />
                              ) : (
                                draftBill?.walletApplied > 0 ? "Applied" : "Apply"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>}
              </div>
              <div className="flex flex-col gap-2">
                {paymentStatus && (
                  <div className="flex gap-2">
                    <button
                      className="bg-primaryColor flex gap-2 items-center text-white py-2 px-4 rounded-md hover:bg-primaryHoverColor focus:outline-none focus:ring-2 focus:ring-primaryColor"
                      onClick={() => handleWalletEdit(clientDuringBill, draftBill)}
                    >
                      {isLoading ? (
                        <NewLoader />
                      ) : (
                        <>
                          <MdModeEditOutline /> Wallet
                        </>
                      )}

                    </button>
                    <button
                      className="bg-primaryColor flex gap-2 items-center text-white py-2 px-2 rounded-md hover:bg-primaryHoverColor focus:outline-none focus:ring-2 focus:ring-primaryColor"
                      onClick={handleCompleteButtonClick}
                    >
                      <MdModeEditOutline /> Payments
                    </button>
                  </div>
                )}
                <div className="flex flex-row items-center gap-7">
                  <button
                    className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-primaryColor"
                    type="submit"
                    onClick={() => {
                      setId(id);
                      setOpenDeleteModal(true);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="bg-primaryColor text-white py-2 px-4 rounded-md hover:bg-primaryHoverColor focus:outline-none focus:ring-2 focus:ring-primaryColor"
                    onClick={() => {
                      dispatch(clearNewBill());
                      setViewModal(false);
                      dispatch(clearDraftBill());
                      dispatch(clearClient());
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <PaymentEditModal
        isOpen={openEditPayment}
        onClose={() => setOpenEditPayment(false)}
        FetchBillingData={FetchBillingData}
        singleInvoice={draftBill}
        setIsEdited={setIsEdited}
        isEdited={isEdited}
      />
      <Modal open={pdfViewerOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            maxHeight: "90vh",
            overflow: "scroll",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            outline: "none",
            borderRadius: "8px",
          }}
          className="no-scrollbar"
        >
          <div className="flex justify-end">
            <button
              onClick={() => setPdfViewerOpen(false)}
              className="text-2xl"
            >
              x
            </button>
          </div>
          <div>
            <iframe
              title="PDF Viewer"
              className="w-full h-screen no-scrollbar"
              srcDoc={selectedContent}
              style={{ border: "none" }}
            // scrolling="no"
            />
          </div>
        </Box>
      </Modal>

      <style jsx>{`
        .no-scrollbar {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }

        .no-scrollbar::-webkit-scrollbar {
          display: none; /* Chrome, Safari, and Opera */
        }
      `}</style>

      <InvoiceViewDeleteModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        FetchBillingData={FetchBillingData}
        setViewModal={setViewModal}
        id={id}
        setId={setId}
        getBillData={getBillData}
      />
    </React.Fragment>
  );
};

export default InvoiceViewModal;
