import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReportFilterType from '../Report/ReportFilterType';

const StaffServices = ({ staff }) => {
  const [serviceHistory, setServiceHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("month");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  const branch = useSelector((state) => state?.main?.branch);

  const itemsPerPage = 5;

  // Fetch service history from the backend
  const fetchServiceHistory = async () => {
    if (!staff?._id) {
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/reports/staff-performance`, {
        params: {
          organizationId: branch?._id,
          staffId: staff._id,
          startDate: startDate || undefined, 
          endDate: endDate || undefined,
          filterType: selectedCategory || "month",
          page: currentPage,
          limit: itemsPerPage,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
        },
      });
      setServiceHistory(response.data.data);
      setTotalItems(response.data.totalCount);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServiceHistory();
  }, [staff?._id, selectedCategory, startDate, endDate, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const headerData = [
    { label: "Staff Name", field: "staffName" },
    { label: "Service Name", field: "serviceName" },
    { label: "Service Date", field: "serviceDate" },
    { label: "Total Service Count", field: "totalServiceCount" },
    { label: "Total Revenue", field: "totalRevenue" },
    { label: "Staff Status", field: "staffStatus" },
  ];

  return (
    <div className="min-h-[450px] flex flex-col">
      <div className="flex justify-end w-[100%] px-2 mb-4">
        <ReportFilterType
          setSelectedCategory={(value) => {
            setError(null)
            setSelectedCategory(value);
            setCurrentPage(1); 
          }}
          setStartDate={(value) => {
            setError(null)
            setStartDate(value);
            setCurrentPage(1); 
          }}
          setEndDate={(value) => {
            setError(null)
            setEndDate(value);
            setCurrentPage(1); 
          }}
        />
      </div>

      {/* Main content */}
      {!staff?._id ? (
        <div className="flex items-center justify-center min-h-[400px]">
          Please Select a Staff
        </div>
      ) : loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : (
        <>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y p-2 dynamic-table">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left border-2 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Sl. No.
                  </th>
                  {headerData?.map((header, index) => (
                    <th
                      key={index}
                      className="px-6 py-3 text-left border-2 text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header?.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {serviceHistory?.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm border-[1px] text-gray-900">
                      {itemsPerPage * (currentPage - 1) + rowIndex + 1}
                    </td>
                    {headerData?.map((header, colIndex) => (
                      <td
                        key={colIndex}
                        style={{ whiteSpace: "pre-line" }}
                        className="px-6 py-4 whitespace-nowrap text-sm border-[1px] text-gray-900"
                      >
                        {row[header.field]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination and summary */}
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {itemsPerPage * (currentPage - 1) + 1} to{" "}
              {Math.min(itemsPerPage * currentPage, totalItems)} of {totalItems} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                className={`text-sm ${currentPage === Math.ceil(totalItems / itemsPerPage)
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StaffServices;
