import Calendar from "react-calendar";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CalendarIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import { setAppointmentFromCalendar } from "../../state-management/main/actions";
import { useEffect } from "react";
function getCurrentDateTimes(selectedDate) {
  let date = new Date(selectedDate);
  const now = new Date();

  if (!(date instanceof Date) || isNaN(date)) {
    date = now;
  } else {
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    if (date > today) {
      // If the selected date is in the future, combine it with the current time
      date.setHours(now.getHours());
      date.setMinutes(now.getMinutes());
      date.setSeconds(now.getSeconds());
      date.setMilliseconds(now.getMilliseconds());
    } else if (date.getTime() === today.getTime()) {
      // If the selected date is today, return the current date and time
      date = now;
    }
  }

  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  const hour = `${date.getHours()}`.padStart(2, "0");
  const minute = `${date.getMinutes()}`.padStart(2, "0");

  return `${year}-${month}-${day}T${hour}:${minute}`;
}

const Header = ({
  weekMonthBtnChange,
  setWeekMonthBtnChange,
  handleToggle,
  visible,
  // calendarIconRef,
  prevDate,
  nextDate,
  // showCalendarModal,
  // toggleCalendarModal,
}) => {
  // const [calenderValue, setCalenderValue] = useState(new Date());
  const main = useSelector((state) => state?.main);
  const [time, setTime] = useState("");
  const appointmentDetailFromCalendar = main?.appointmentDetailFromCalendar;
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const dispatch = useDispatch();
  useEffect(() => {
    const getGreeting = () => {
      const nowTime = new Date();
      const hours = nowTime.getHours();

      if (hours < 12) {
        return "Good Morning";
      } else if (hours < 18) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    };

    setTime(getGreeting());
  }, []);

  // const renderCalendar = () => {
  //   return (
  //     <>
  //       {showCalendarModal && (
  //         <div
  //           className="absolute z-50 bg-white rounded-lg ml-10 mt-10 p-4 shadow-lg "
  //           style={{
  //             right:
  //               calendarIconRef.current.offsetRight +
  //               calendarIconRef.current.offsetWidth,
  //             top: calendarIconRef.current.offsetTop,
  //           }}
  //         >
  //           <button
  //             className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
  //             onClick={() => {
  //               const selectedDate = new Date(calenderValue);
  //               selectedDate.setDate(selectedDate.getDate() + 1);
  //               dispatch(
  //                 setAppointmentFromCalendar({
  //                   ...appointmentDetailFromCalendar,
  //                   selectedDate,
  //                 })
  //               );
  //               toggleCalendarModal();
  //             }}
  //           >
  //             Close
  //           </button>
  //         </div>
  //       )}
  //     </>
  //   );
  // };

  const handleCalanderChange = (e, type) => {
    const selectedDate = e.target.value;
    const currentDateTime = getCurrentDateTimes(selectedDate);

    if (type === 'from') {
      dispatch(
        setAppointmentFromCalendar({
          ...appointmentDetailFromCalendar,
          selectedDate: currentDateTime,
        })
      );
    }
    if (type === 'to') {
      dispatch(
        setAppointmentFromCalendar({
          ...appointmentDetailFromCalendar,
          selectedToDate: currentDateTime,
        }))
    }
  };

  return (
    <div className="flex md:flex-row flex-col justify-between items-center py-2">
      <div className="flex gap-2 items-center">
        <span className="text-xl font-bold">Statistics</span>
        <span className="cursor-pointer">
          {" "}
          <IoMdArrowDropdown onClick={handleToggle} size={25} />
        </span>
        <div className="flex gap-2">
          <div className="flex justify-between items-center w-86">
            <span className="text-primaryColor hover:text-primaryHoverColor">
              From
            </span>
            <ChevronLeftIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => prevDate('from')}
            />
            <input
              aria-label="Date"
              type="date"
              className="text-white w-[20px]"
              value={appointmentDetailFromCalendar.selectedDate}
              onChange={(e) => handleCalanderChange(e, 'from')}
            />
            <ChevronRightIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => nextDate('from')}
            />
          </div>
          <div className="flex justify-between items-center w-86">
            <span className="text-primaryColor hover:text-primaryHoverColor">
              To
            </span>
            <ChevronLeftIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => prevDate('from')}
            />
            <input
              aria-label="Date"
              type="date"
              className="text-white w-[20px]"
              value={appointmentDetailFromCalendar.selectedToDate}
              onChange={(e) => handleCalanderChange(e, 'to')}
            />
            <ChevronRightIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => nextDate('to')}
            />
          </div>
          {/* {renderCalendar()} */}
        </div>
      </div>

      {visible && (
        <div className="hidden md:flex font-bold md:text-2xl">
          {time}, {userRole === "admin" ? "Admin" : "Staff"}
        </div>
      )}
      <div className="flex gap-2 items-center ">
        {!visible && (
          <span className="hidden md:flex font-bold md:text-2xl">
            {time}, {userRole === "admin" ? "Admin" : "Staff"}
          </span>
        )}

        {(userRole === "admin" ||
          (userRole === "staff" &&
            userRoleName?.roleId?.permissions?.dashboard
              ?.dashboardStatistics)) &&
          visible && (
            <div className=" flex items-center text-xs font-bold tracking-wide justify-end">
              <div
                onClick={() => setWeekMonthBtnChange("day")}
                className={
                  weekMonthBtnChange === "day"
                    ? "bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 text-white px-4 py-3 cursor-pointer"
                    : "bg-[#eaeaea] hover:bg-[#d1d1d1] transition ease-in-out hover:duration-300 px-4 py-3 cursor-pointer"
                }
              >
                Day
              </div>

              <div
                onClick={() => setWeekMonthBtnChange("month")}
                className={
                  weekMonthBtnChange === "month"
                    ? "bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 text-white px-4 py-3 cursor-pointer"
                    : "bg-[#eaeaea] hover:bg-[#d1d1d1] transition ease-in-out hover:duration-300 px-4 py-3 cursor-pointer"
                }
              >
                Month
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default Header;
