import React, { useState, useEffect } from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import AdminProfileDropDown from "./AdminProfileDropDown";
// import NotificationDropdown from "./NotificationDropdown";
import Issue from "./Issue";
// import Tostify from "../Common/Tostify";
import { decryptData } from "../Common/localStorageUtils";
import AcceptRequest from "./AcceptRequest";
import axios from "axios";
import { BsStopwatchFill } from "react-icons/bs";
import Pusher from "pusher-js";
import BranchSwitch from "./BranchSwitch";
import InvoiceModalTable from "../../Components/Invoices/InvoiceModal/InvoiceTableModal";
import AppoinementTableModal from "../../Components/AppointMent/AppoinementTableModal";
import { RiBillLine } from "react-icons/ri";
import { SlCalender } from "react-icons/sl";
import { BiError } from "react-icons/bi";
import { RiCustomerService2Fill } from "react-icons/ri";

const Navbar = ({ toggleSidebar }) => {
  const [openIssue, setOpenIssue] = useState(false);
  const [openAcceptService, setOpenAcceptService] = useState(false);
  const [openInvoiceTable, setOpenInvoiceTable] = useState(false);
  const [openAppointmentTable, setOpenAppointmentTable] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [services, setServices] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);
  const role = decryptData()?.user?.role;
  const token = "Bearer " + localStorage.getItem("refresh_token");

  const staffId = decryptData()?.userTypeData?._id;

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/notification/get-notification-by-staff`, {
        headers: {
          Authorization: token,
        },
        params: { staffId },
      });

      if (response.data.success) {
        setServices(response.data.data || []);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setServices([])
    }
  };
  useEffect(() => {

    fetchNotifications();
  }, [staffId, token]); // Re-fetch when staffId or token changes

  useEffect(() => {
    const pusher = new Pusher("785220f142c162f4967d", {
      cluster: "ap2",
      useTLS: true,
    });

    const channel = pusher.subscribe(`staff-${staffId}`);

    channel.bind("service-assignment", function (data) {
      console.log("service from pusher", data);
      if (data.staffId === staffId) {
        setIsAvailable(true);
        setServices((prevServices) => {
          const isServiceExists = prevServices?.some(
            (service) => service._id === data._id
          );
          return isServiceExists ? prevServices : [...prevServices, data];
        });
      }
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, [staffId]);

  const handleOpenServiceRequests = () => {
    console.log("clicked");
    setOpenAcceptService(true);
  };

  return (
    <div
      id="nav"
      className="w-full h-16 bg-white flex flex-row items-center justify-between text-gray-600 shadow top-4 overflow-x-auto xs:overflow-x-hidden"
    >
      {services?.length > 0 && (
        <AcceptRequest
          openAcceptService={openAcceptService}
          setOpenAcceptService={setOpenAcceptService}
          services={services}
          setServices={setServices}
          setIsAvailable={setIsAvailable}
          fetchNotifications={fetchNotifications}
        />
      )}

      <div className="ml-5 flex gap-1 items-center">
        <AiOutlineMenuUnfold
          className="transition ease-in-out hover:text-primaryColor hover:duration-300 mr-5"
          onClick={toggleSidebar}
          size={25}
        />

        <div className="hidden md:flex flex-col gap-2 items-center md:justify-center lg:flex-row">
          <RiCustomerService2Fill size={20} />
          <div className="flex flex-col">
            <span className="hidden lg:flex text-xs text-[#8A8A8A]">
              Customer Care
            </span>
            <span className="text-xs text-[#4B465C]">+916483984738</span>
          </div>
        </div>
      </div>

      <div className="hidden md:flex">
        <BranchSwitch />
      </div>

      <div className=" flex flex-row items-center gap-3 mr-5 relative">
        <InvoiceModalTable
          openInvoiceTable={openInvoiceTable}
          setOpenInvoiceTable={setOpenInvoiceTable}
        />

        <AppoinementTableModal
          openAppointmentTable={openAppointmentTable}
          setOpenAppointmentTable={setOpenAppointmentTable}
        />

        <div className="hidden md:flex gap-2 lg:gap-6">
          <button
            type="button"
            className="relative rounded-full text-[#4b5563] flex items-center justify-center h-8 w-8 focus:outline-none hover:text-primaryColor hover:duration-300"
            onClick={() => setOpenInvoiceTable(true)}
          >
            <span className="absolute -inset-1.5" />
            <RiBillLine size={26} aria-hidden="true" />
          </button>

          <button
            type="button"
            className="relative rounded-full text-[#4b5563] flex items-center justify-center h-8 w-8 focus:outline-none hover:text-primaryColor hover:duration-300"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => {
              setOpenIssue(true);
            }}
          >
            <span className="absolute -inset-1.5" />
            <BiError size={28} aria-hidden="true" />
          </button>
          <button
            type="button"
            className="relative rounded-full text-[#4b5563] flex items-center justify-center h-8 w-8 focus:outline-none hover:text-primaryColor hover:duration-300"
            onClick={() => setOpenAppointmentTable(true)}
          >
            <span className="absolute -inset-1.5" />
            <SlCalender size={24} aria-hidden="true" />
          </button>

          <div className="relative">
            {isAvailable && services?.length > 0 && (
              <span className="bg-green-500 bottom-4 w-2.5 h-2.5 rounded-full absolute top-1 right-0">
                {services?.length}
              </span>
            )}
            <BsStopwatchFill
              size={24}
              className="hover:text-primaryColor hover:duration-300 cursor-pointer rounded-full"
              onClick={handleOpenServiceRequests}
            />
          </div>
          {isHovered && (
            <div className="absolute top-0 right-24 w-28 transform -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow text-center z-10">
              Having an Issue?
            </div>
          )}

          <Issue openIssue={openIssue} setOpenIssue={setOpenIssue} />
        </div>
        <AdminProfileDropDown />
      </div>
    </div>
  );
};

export default Navbar;
