import axios from "axios";

export const getTopStaffByServices = async ({
  page,
  organizationId,
  filterType,
  startDate,
  endDate,
  limit,
}) => {
  const authToken = "Bearer " + localStorage.getItem("refresh_token");
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/analytics/top-staff-by-services/?page=${page}&limit=${limit}&organizationId=${organizationId}&filterType=${filterType}&startDate=${startDate}&endDate=${endDate}`,
    headers: {
      Authorization: `${authToken}`,
    },
  };

  try {
    const response = await axios.request(config);
    return response?.data;
  } catch (error) {
    console.error("Error making API call:", error);
    throw error;
  }
};

export const fetchAppointmentsAnalytics = async (
  fromDate,
  toDate,
  currentPage,
  limit,
  filterType,
  branch
) => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  if (!token) return;
  if (!branch) {
    return;
  }

  try {
    let url = `${process.env.REACT_APP_API_URL}/appointment/all-appointments?page=${currentPage}&limit=${limit}&branch=${branch}`;

    url += `&filterType=${filterType}&fromDate=${fromDate}&toDate=${toDate}`;
    const config = {
      method: "get",
      url: url,
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.request(config);

    // Check if data is returned and update state accordingly
    if (response.data.success && response.data.data.length > 0) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching appointments:", error);
    throw error;
  }
};

export const fetchAppointmentStatusAnalytics = async (
  fromDate,
  toDate,
  currentPage,
  limit,
  filterType,
  branch
) => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  if (!token) return;
  // if (!branch) {
  //   return;
  // }

  try {
    let url = `${process.env.REACT_APP_API_URL}/analytics/get-aggregated-appointments?page=${currentPage}&limit=${limit}&branch=${branch}`;

    url += `&filterType=${filterType}&fromDate=${fromDate}&toDate=${toDate}`;
    const config = {
      method: "get",
      url: url,
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.request(config);

    if (response.data.success && response.data.data.length > 0) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching appointments:", error);
    throw error;
  }
};

export const getDailyBillingRevenue = async (organizationId, queryParams) => {
  const token = "Bearer " + localStorage.getItem("refresh_token");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/analytics/daily-revenue/${organizationId}`,
      {
        params: queryParams,
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching daily billing revenue:", error);
    throw error;
  }
};
