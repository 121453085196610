import React, { useEffect, useState } from "react";
import { AnalyticsDynamicTable } from "./AnalyticsDynamicTable";
import * as XLSX from "xlsx";
import excel from "../../Assets/xls.png";
import pdf from "../../Assets/Pdf.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { fetchAppointmentsAnalytics, fetchAppointmentStatusAnalytics, getDailyBillingRevenue, getTopStaffByServices } from "./analyticsData";
import { useSelector } from "react-redux";
import InvoiceDropdown from "../Invoices/InvoiceModal/InvoiceDropdown";

export const AnalyticsTablesView = ({
  // dailyRevenueData,
  activeStaff,
  inactiveStaff,
  // dailySumsForPayments,
  dailyRevenueFromMembership,
  // dailyServiceVsStaff,
  selectedFilter,
  from,
  to,
}) => {
  //service vs staff analytics data states
  const [staffVsServiceData, setStaffVsServiceData] = useState([]);
  const [serviceVsStaffPagination, setServiceVsStaffPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalDocuments: 0,
  });
  //appointment analytics data states
  const [appointmentData, setAppointmentData] = useState([]);
  const [appointmentPagination, setAppointmentPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalDocuments: 0,
  })
  const [aggregatedAppointments, setAggregatedAppointments] = useState([]);
  const [aggregatedAppointmentsPagination, setAggregatedAppointmentsPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalDocuments: 0,
  })
  const [appointmentFilterType, setAppointmentFilterType] = useState("createdAt");
  const FILTER_OPTIONS = [
    { value: "createdAt", label: "Booking" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "rescheduled", label: "Rescheduled" },
    { value: "cancelled", label: "Cancelled" },
    { value: "inquiry", label: "Quick Inquiry" }
  ];

  //payments analytics data states
  const [paymentsData, setPaymentsData] = useState([]);
  const [paymentsPagination, setPaymentsPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalDocuments: 0,
  })
  const branch = useSelector(state => state?.main?.branch)
  const [loading, setLoading] = useState(false);

  const handleAppointmentFilterChange = (value) => {
    setAppointmentFilterType(value);
    setAppointmentPagination({ ...appointmentPagination, currentPage: 1 });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await getTopStaffByServices({
          organizationId: branch?._id,
          filterType: "customRange",
          startDate: from,
          endDate: to,
          page: serviceVsStaffPagination?.currentPage,
          limit: 5,
        });

        const formattedData = response?.data?.map((item) => ({
          staffName: item?.staffName,
          serviceName: `${item?.serviceName} (${item?.serviceDuration} min)`,
          totalServiceCount: item?.totalServiceCount,
          paid: item?.paidCount,
          membershipServiceCount: item?.membershipServiceCount,
          totalRevenue: `${item?.totalRevenue?.toFixed(2)}`,
        }));

        setStaffVsServiceData(formattedData);
        setServiceVsStaffPagination(response?.pagination);
      } catch (error) {
        setStaffVsServiceData([]);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [from, to, branch?._id, serviceVsStaffPagination?.currentPage]);

  useEffect(() => {
    const fetchApppointmentsData = async () => {
      try {
        const response = await fetchAppointmentsAnalytics(
          from,
          to,
          appointmentPagination?.currentPage,
          5,
          appointmentFilterType,
          branch?._id
        );
        setAppointmentData(response?.data);
        setAppointmentPagination({
          currentPage: response?.pagination?.currentPage,
          totalPages: response?.pagination?.totalPages,
          totalDocuments: response?.pagination?.totalRecords,
        });
      } catch (error) {
        console.error(error);
        setAppointmentData([]);
      }
    }

    const fetchStatusData = async () => {
      try {
        const response = await fetchAppointmentStatusAnalytics(
          from,
          to,
          aggregatedAppointmentsPagination?.currentPage,
          5,
          appointmentFilterType,
          branch?._id
        );
        setAggregatedAppointments(response?.data);
        setAggregatedAppointmentsPagination({
          currentPage: response?.pagination?.currentPage,
          totalPages: response?.pagination?.totalPages,
          totalDocuments: response?.pagination?.totalRecords,
        });
      } catch (error) {
        console.error(error);
        setAggregatedAppointments([]);
      }
    }

    fetchStatusData()
    fetchApppointmentsData()
  }, [from, to, appointmentPagination?.currentPage, appointmentFilterType, branch?._id, aggregatedAppointmentsPagination?.currentPage]);

  useEffect(() => {
    const fetchRevenueAndPaymentsData = async () => {
      try {
        const data = await getDailyBillingRevenue(branch?._id, {
          from,
          to,
          limit: 5,
          page: paymentsPagination?.currentPage || 1,
        });
        setPaymentsData(data?.data);
        setPaymentsPagination(data?.pagination);
      } catch (error) {
        console.error("Error fetching billing data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRevenueAndPaymentsData();
  }, [from, to, branch?._id, paymentsPagination?.currentPage]);

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  // DailyRevenue data of Table view start
  const dailyRevenueHeaderData = [
    { field: "id", label: "ID" },
    { field: "date", label: "Date" },
    { field: "rupees", label: "rupees" },
  ];

  const revenueRowData = paymentsData?.map((entry, index) => ({
    id: index + 1,
    date: new Date(entry.date).toLocaleDateString(),
    rupees: entry.subTotal || 0,
  }));;

  // DailyRevenue data of Table view end

  // DailyAppointments data of Table view start
  const last7DaysAppointmentsHeaderData = [
    { label: "Appointment Date", field: "appointmentDate" },
    { label: "Customer Name", field: "customerName" },
    { label: "Phone", field: "customerPhone" },
    { label: "Gender", field: "gender" },
    { label: "Status", field: "status" },
    { label: "Organization Name", field: "organization.name" },
    { label: "Note", field: "note" },
  ];

  const last7DaysAppointmentsRowData = appointmentData?.map((appointment) => ({
    appointmentDate: new Date(appointment?.appointmentDate).toLocaleString(),
    customerName: appointment?.customerName,
    customerPhone: appointment?.customerPhone,
    gender: appointment?.gender,
    status: appointment?.status,
    "organization.name": appointment?.organization?.name,
    note: appointment?.note || "N/A",
  }));

  const aggregatedHeaderData = [
    { label: "Date", field: "date" },
    { label: "Pending", field: "pending" },
    { label: "Completed", field: "completed" },
    { label: "Rescheduled", field: "rescheduled" },
  ];

  const aggregatedRowData = aggregatedAppointments?.map((data) => ({
    date: data?.date,
    pending: data?.pending,
    completed: data?.completed,
    rescheduled: data?.rescheduled,
  }));

  const staffStatusHeaderData = [
    { field: "Active", label: "ACTIVE" },
    { field: "InActive", label: "INACTIVE" },
  ];
  const staffStatusRowData = [
    { Active: activeStaff?.length, InActive: inactiveStaff?.length },
  ];
  // StaffsStatus data of Table view end

  // Payments data of Table view start
  const paymentsHeaderData = [
    { field: "id", label: "ID" },
    { field: "date", label: "DATE" },
    // { field: "PaymentMethod", label: "Payment Method" },
    { field: "cash", label: "CASH" },
    { field: "card", label: "CARD" },
    { field: "upi", label: "UPI" },
    { field: "total", label: "TOTAL" },
    { field: "expenses", label: "Expenses" },
    { field: "subTotal", label: "SubTotal" },
  ];

  const rowDataOfPayments = paymentsData?.map((entry, index) => ({
    id: index + 1,
    date: new Date(entry.date).toLocaleDateString(),
    cash: entry.cash || 0,
    card: entry.card || 0,
    upi: entry.upi || 0,
    total: (entry.cash || 0) + (entry.card || 0) + (entry.upi || 0),
    expenses: entry.expenses || 0,
    subTotal: (entry.cash || 0) + (entry.card || 0) + (entry.upi || 0) - (entry.expenses || 0),
  }))

  const membershipHeaderData = [
    { field: "id", label: "ID" },
    { field: "Date", label: "DATE" },
    { field: "Price", label: "PRICE" },
  ];

  const sameDateMembershipHeaderData = [
    { field: "id", label: "ID" },
    { field: "Date", label: "DATE" },
    { field: "Price", label: "PRICE" },
  ];

  let membershipRowData;
  const dataByDate = {};
  Object.keys(dailyRevenueFromMembership).forEach((day) => {
    dailyRevenueFromMembership[day].forEach((payment) => {
      const date = payment.date;
      if (!dataByDate[date]) {
        dataByDate[date] = [];
      }
      dataByDate[date].push(payment);
    });
  });
  membershipRowData = Object.keys(dataByDate).map((date, index) => {
    let price = dataByDate[date].reduce((total, data) => total + data.price, 0);
    return {
      id: index + 1,
      Date: date,
      Price: price,
    };
  });
  membershipRowData = membershipRowData.sort((a, b) => {
    const dateA = parseDate(a.Date);
    const dateB = parseDate(b.Date);
    return dateA - dateB;
  });
  for (let i = 0; i < membershipRowData.length; i++) {
    membershipRowData[i].id = i + 1;
  }

  const headerData = [
    { field: "id", label: "ID" },
    { field: "name", label: "Name" },
    { field: "serviceName", label: "Service Name" },
    { field: "service", label: "No.Of Service" },
    { field: "date", label: "date" },
  ];

  const serviceVsStaffHeaderData = [
    { label: "Staff Name", field: "staffName" },
    { label: "Service Name", field: "serviceName" },
    { label: "Membership Service Count", field: "membershipServiceCount" },
    { label: "Paid", field: "paid" },
    { label: "Total Service Count", field: "totalServiceCount" },
    { label: "Total Revenue", field: "totalRevenue" },
  ];

  const DownloadExcelData = (data, fileName) => {
    if (data?.length === 0) {
      return
    }
    let filteredData;
    if (Array.isArray(data)) {
      filteredData = data?.map((item) => ({
        ...item,
      }));
    } else if (
      typeof data === "object" &&
      data !== null &&
      !Array.isArray(data)
    ) {
      filteredData = {
        ...data,
      };
    } else {
      console.log("data not found");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const colWidths = Object.keys(filteredData[0]).map((key) =>
      filteredData.reduce(
        (acc, row) => Math.max(acc, String(row[key]).length),
        key.length
      )
    );
    const gapWidth = 2;
    const adjustedColWidths = colWidths.map((width) => width + gapWidth);
    worksheet["!cols"] = adjustedColWidths.map((width) => ({ wch: width }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };

  const DownloadPdfData = (data, headerData, fileName) => {
    if (data?.length === 0) {
      return
    }
    const doc = new jsPDF();
    const tableHeaders = headerData.map((header) => header.label);

    // Add a title to the PDF
    doc.setFontSize(16);
    doc.text("Report Table", 20, 20);

    // Add a date to the PDF
    const date = new Date().toLocaleDateString();
    doc.setFontSize(12);
    doc.text(`Date: ${date}`, 20, 30);

    // Add the table content to the PDF using autoTable
    doc.autoTable({
      startY: 40,
      head: [tableHeaders],
      body: data.map((row) =>
        headerData.map((header) => row[header.field] || "")
      ),
      styles: { cellPadding: 2, fontSize: 10 },
      headStyles: { fillColor: [254, 135, 64] },
      alternateRowStyles: { fillColor: [250, 250, 250] },
    });

    // Save the PDF
    doc.save(`${fileName}.pdf`);
  };

  const renderFilteredData = () => {
    switch (selectedFilter) {
      case "Daily Revenue Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">Daily Revenue</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(
                        revenueRowData,
                        "Daily Revenue.xlsx"
                      )
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        revenueRowData,
                        dailyRevenueHeaderData,
                        "Daily Revenue"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                rowData={revenueRowData}
                headerData={dailyRevenueHeaderData}
                pagination={paymentsPagination}
                setPagination={setPaymentsPagination}
              />
            </span>
          </div>
        );
      case "Appointments Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">
                  Appointments per day
                </h4>
                <div className="flex gap-2">
                  <div className="relative md:w-48">
                    <InvoiceDropdown
                      items={FILTER_OPTIONS.map((option) => option.label)}
                      onSelect={(label) => {
                        const option = FILTER_OPTIONS?.find(
                          (opt) => opt.label === label
                        );
                        handleAppointmentFilterChange(option?.value);
                      }}
                      autoComplete="off"
                      selectedItem={
                        FILTER_OPTIONS?.find((opt) => opt?.value === appointmentFilterType)?.label
                      }
                      label={"Select Filter"}
                      className="w-48"
                    />
                  </div>
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(
                        last7DaysAppointmentsRowData,
                        "Appointments per day.xlsx"
                      )
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        last7DaysAppointmentsRowData,
                        last7DaysAppointmentsHeaderData,
                        "Appointment Data"
                      )
                    }
                  />
                </div>
              </div>
              <AnalyticsDynamicTable
                rowData={last7DaysAppointmentsRowData}
                headerData={last7DaysAppointmentsHeaderData}
                pagination={appointmentPagination}
                setPagination={setAppointmentPagination}
              />
            </span>
          </div>
        );
      case "Appointments Status":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">
                  Appointments per day Status
                </h4>
                <div className="flex gap-2">
                  <div className="relative md:w-48">
                    <InvoiceDropdown
                      items={FILTER_OPTIONS.map((option) => option.label)}
                      onSelect={(label) => {
                        const option = FILTER_OPTIONS?.find(
                          (opt) => opt.label === label
                        );
                        handleAppointmentFilterChange(option?.value);
                      }}
                      autoComplete="off"
                      selectedItem={
                        FILTER_OPTIONS?.find((opt) => opt?.value === appointmentFilterType)?.label
                      }
                      label={"Select Filter"}
                      className="w-48"
                    />
                  </div>
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(
                        last7DaysAppointmentsRowData,
                        "Appointments per day.xlsx"
                      )
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        last7DaysAppointmentsRowData,
                        last7DaysAppointmentsHeaderData,
                        "Appointment Data"
                      )
                    }
                  />
                </div>
              </div>
              <AnalyticsDynamicTable
                rowData={aggregatedRowData}
                headerData={aggregatedHeaderData}
                pagination={aggregatedAppointmentsPagination}
                setPagination={setAggregatedAppointmentsPagination}
              />
            </span>
          </div>
        );
      case "Staff Status Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-3 flex items-center justify-between bg-white">
                <h4 className="text-2xl font-bold mr-2">Staffs Status</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(
                        staffStatusRowData,
                        "Staffs Status.xlsx"
                      )
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        staffStatusRowData,
                        staffStatusHeaderData,
                        "Staffs Status"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                rowData={staffStatusRowData}
                headerData={staffStatusHeaderData}
              />
            </span>
          </div>
        );
      case "Payments Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">Payments</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(rowDataOfPayments, "Payments.xlsx")
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        rowDataOfPayments,
                        paymentsHeaderData,
                        "payments Data"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                rowData={rowDataOfPayments}
                headerData={paymentsHeaderData}
                pagination={paymentsPagination}
                setPagination={setPaymentsPagination}
              />
            </span>
          </div>
        );
      case "Membership Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">Membership Revenue</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(
                        membershipRowData,
                        "Membership Revenue.xlsx"
                      )
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        membershipRowData,
                        membershipHeaderData,
                        "Membership revenue"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                rowData={membershipRowData}
                headerData={membershipHeaderData}
              />
            </span>
          </div>
        );
      case "Employee Vs Service Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">Employee vs Service</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(staffVsServiceData, "Employee vs Service.xlsx")
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        staffVsServiceData,
                        headerData,
                        "Employee vs Service"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                setPagination={setServiceVsStaffPagination}
                rowData={staffVsServiceData}
                headerData={serviceVsStaffHeaderData}
                pagination={serviceVsStaffPagination}
              />
            </span>
          </div>
        );
      case "All":
      default:
        return (
          <>
            <div className="flex lg:flex-row flex-col gap-6 pt-4">
              <span className="w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">Payments</h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(rowDataOfPayments, "Payments.xlsx")
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          rowDataOfPayments,
                          paymentsHeaderData,
                          "payments Data"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  rowData={rowDataOfPayments}
                  headerData={paymentsHeaderData}
                  pagination={paymentsPagination}
                  setPagination={setPaymentsPagination}
                />
              </span>
            </div>
            <div className="flex lg:flex-row flex-col gap-6 pt-4">
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">
                    Appointments per day
                  </h4>
                  <div className="flex gap-2">
                    <div className="relative md:w-48">
                      <InvoiceDropdown
                        items={FILTER_OPTIONS.map((option) => option.label)}
                        onSelect={(label) => {
                          const option = FILTER_OPTIONS?.find(
                            (opt) => opt.label === label
                          );
                          handleAppointmentFilterChange(option?.value);
                        }}
                        autoComplete="off"
                        selectedItem={
                          FILTER_OPTIONS?.find((opt) => opt?.value === appointmentFilterType)?.label
                        }
                        label={"Select Filter"}
                        className="w-48"
                      />
                    </div>
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(
                          last7DaysAppointmentsRowData,
                          "Appointments per day.xlsx"
                        )
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          last7DaysAppointmentsRowData,
                          last7DaysAppointmentsHeaderData,
                          "Appointment Data"
                        )
                      }
                    />
                  </div>
                </div>
                <AnalyticsDynamicTable
                  rowData={last7DaysAppointmentsRowData}
                  headerData={last7DaysAppointmentsHeaderData}
                  pagination={appointmentPagination}
                  setPagination={setAppointmentPagination}
                />
              </span>
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">
                    Appointments Status
                  </h4>
                  <div className="flex gap-2">
                    <div className="relative md:w-48">
                      <InvoiceDropdown
                        items={FILTER_OPTIONS.map((option) => option.label)}
                        onSelect={(label) => {
                          const option = FILTER_OPTIONS?.find(
                            (opt) => opt.label === label
                          );
                          handleAppointmentFilterChange(option?.value);
                        }}
                        autoComplete="off"
                        selectedItem={
                          FILTER_OPTIONS?.find((opt) => opt?.value === appointmentFilterType)?.label
                        }
                        label={"Select Filter"}
                        className="w-48"
                      />
                    </div>
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(
                          last7DaysAppointmentsRowData,
                          "Appointments per day.xlsx"
                        )
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          last7DaysAppointmentsRowData,
                          last7DaysAppointmentsHeaderData,
                          "Appointment Data"
                        )
                      }
                    />
                  </div>
                </div>
                <AnalyticsDynamicTable
                  rowData={aggregatedRowData}
                  headerData={aggregatedHeaderData}
                  pagination={aggregatedAppointmentsPagination}
                  setPagination={setAggregatedAppointmentsPagination}
                />
              </span>
            </div>

            <div className="flex lg:flex-row flex-col gap-6">
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">Staffs Status</h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(
                          staffStatusRowData,
                          "Staffs Status.xlsx"
                        )
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          staffStatusRowData,
                          staffStatusHeaderData,
                          "Staffs Status"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  rowData={staffStatusRowData}
                  headerData={staffStatusHeaderData}
                />
              </span>
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">Daily Revenue</h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(
                          revenueRowData,
                          "Daily Revenue.xlsx"
                        )
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          revenueRowData,
                          dailyRevenueHeaderData,
                          "Daily Revenue"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  rowData={revenueRowData}
                  headerData={dailyRevenueHeaderData}
                  pagination={paymentsPagination}
                  setPagination={setPaymentsPagination}
                />
              </span>
            </div>

            <div className="flex lg:flex-row flex-col gap-6">
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">Membership Revenue</h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(
                          membershipRowData,
                          "Membership Revenue.xlsx"
                        )
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          membershipRowData,
                          membershipHeaderData,
                          "Membership revenue"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  rowData={membershipRowData}
                  headerData={
                    from === to
                      ? sameDateMembershipHeaderData
                      : membershipHeaderData
                  }
                />
              </span>
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">
                    Employee vs Service
                  </h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(staffVsServiceData, "Employee vs Service.xlsx")
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          staffVsServiceData,
                          headerData,
                          "Employee vs Service"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  pagination={serviceVsStaffPagination}
                  rowData={staffVsServiceData}
                  headerData={serviceVsStaffHeaderData}
                  setPagination={setServiceVsStaffPagination}
                />
              </span>
            </div>
          </>
        );
    }
  };

  return <>{renderFilteredData()}</>;
};
