export const AnalyticsDynamicTable = ({ rowData, headerData, pagination, setPagination }) => {
  const { currentPage = 1, totalDocuments = 0, totalPages = 1 } = pagination || {};

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPagination({ ...pagination, currentPage: newPage });
    }
  };

 
  const indexOfFirstItem = (currentPage - 1) * 5;
  const indexOfLastItem = indexOfFirstItem + 5;
  const shouldShowPagination = totalDocuments > 0;

  return (
    <div className="min-h-[500px] min-w-full overflow-auto divide-gray-200 bg-white shadow-md rounded-lg">
      {rowData?.length === 0 ? (
        <div className="h-[400px] flex justify-center items-center text-xl">
          No Data Found
        </div>
      ) : (
        <div className="overflow-auto max-h-[500px] no-scrollbar">
          <table className="min-w-full divide-y p-2">
            <thead className="bg-gray-50 sticky top-0">
              <tr>
                {headerData?.map((header, index) => (
                  <th
                    key={index}
                    className="px-6 py-3 text-left border-2 border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header?.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {rowData?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headerData.map((header, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ whiteSpace: "pre-line" }}
                      className="px-6 py-4 whitespace-nowrap border border-gray-200 text-sm text-gray-900"
                    >
                      {row[header.field]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination controls */}
      {shouldShowPagination && pagination && (
        <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
          <span className="text-xs xs:text-sm text-gray-900">
            Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, totalDocuments)} of {totalDocuments} Entries
          </span>

          <div className="inline-flex mt-2 xs:mt-0">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`text-sm ${currentPage === 1
                ? "bg-gray-200"
                : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-l`}
            >
              Prev
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`text-sm ${currentPage === totalPages
                ? "bg-gray-200"
                : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-r`}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};