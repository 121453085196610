import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
};

const AddCoupon = (props) => {
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branch = useSelector(state => state?.main?.branch);;
    const [isLoading, setIsLoading] = useState(false);
    const [isApplicableOnOpen, setIsApplicableOnOpen] = useState(false);
    const applicableOnRef = useRef(null);

    const initialState = {
        name: "",
        nameErr: "",
        couponCode: "",
        couponCodeErr: "",
        discountType: "",
        discountTypeErr: "",
        discountValue: "",
        discountValueErr: "",
        minBillAmount: "",
        minBillAmountErr: "",
        allowedPerCustomer: "",
        allowedPerCustomerErr: "",
        startDate: "",
        startDateErr: "",
        endDate: "",
        endDateErr: "",
        description: "",
        internalDescription: "",
        applicableOn: [],
        applicableOnErr: "",
        customers: "",
        customersErr: "",
        allItems: false,
        allLocations: false,
        general: false,
    }
    const [formData, setFormData] = useState(initialState);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData };
        if (name === "discountValue" && updatedFormData["discountType"] === "percentage") {
            if (value >= 100) {
                updatedFormData = {
                    ...updatedFormData,
                    discountValueErr: true,
                };
                alert("Invalid Discount");
                return;
            } else {
                updatedFormData.discountValueErr = false;
            }
        }
        if (name === "discountType" && value === "") {
            updatedFormData.discountValue = "";
        }
        updatedFormData = {
            ...updatedFormData,
            [`${name}Err`]: false,
            [name]: value,
        };
        setFormData(updatedFormData);
    };

    const toggleApplicableOnDropdown = () => setIsApplicableOnOpen(!isApplicableOnOpen);

    const handleApplicableOnClick = (option) => {
        let newSelected;
        if (formData?.applicableOn?.includes(option)) {
            newSelected = formData?.applicableOn?.filter(item => item !== option);
        } else {
            newSelected = [...formData?.applicableOn, option];
        }
        setFormData({
            ...formData,
            applicableOn: newSelected,
            applicableOnErr: newSelected?.length === 0
        });
    };

    const removeApplicableOn = (option) => {
        const newSelected = formData?.applicableOn?.filter(item => item !== option);
        setFormData({
            ...formData,
            applicableOn: newSelected,
            applicableOnErr: newSelected.length === 0
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (applicableOnRef.current && !applicableOnRef.current.contains(event.target)) {
                setIsApplicableOnOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked
        });
    };

    const handleAddCoupon = async (e) => {
        e.preventDefault();
        if (!formData?.name) {
            setFormData({
                ...formData,
                nameErr: true,
            })
        }
        else if (!formData?.couponCode) {
            setFormData({
                ...formData,
                couponCodeErr: true
            })
        }
        else if (!formData?.discountType) {
            setFormData({
                ...formData,
                discountTypeErr: true
            })
        }
        else if (!formData?.discountValue) {
            setFormData({
                ...formData,
                discountValueErr: true
            })
        }
        else if (formData?.discountType === "percentage" && (formData?.discountValue >= 100 || formData?.discountValue <= 0)) {
            alert("Invalid Discount value");
            setFormData({
                ...formData,
                discountValueErr: true,
            })
        }
        else if (!formData?.minBillAmount) {
            setFormData({
                ...formData,
                minBillAmountErr: true
            })
        }
        else if (!formData?.allowedPerCustomer) {
            setFormData({
                ...formData,
                allowedPerCustomerErr: true
            })
        }
        else if (!formData?.customers) {
            setFormData({
                ...formData,
                customersErr: true
            })
        }
        else if (!formData?.startDate) {
            setFormData({
                ...formData,
                startDateErr: true
            })
        }
        else if (!formData?.endDate) {
            setFormData({
                ...formData,
                endDateErr: true
            })
        }
        else if (!formData?.applicableOn) {
            setFormData({
                ...formData,
                applicableOnErr: true
            })
        }
        else {
            let data = JSON.stringify({
                organizationId: branch?._id,
                name: formData?.name,
                discountType: formData?.discountType,
                couponCode: formData.couponCode,
                discountValue: formData?.discountValue,
                minAmount: formData?.minBillAmount,
                allowedPerCustomer: formData?.allowedPerCustomer,
                eligibleCustomer: formData?.customers,
                applicableOn: formData?.applicableOn,
                startDate: formData?.startDate,
                endDate: formData?.endDate,
                description: formData?.description,
            });

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/coupon/create`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                data: data,
            };
            setIsLoading(true);
            axios
                .request(config)
                .then((response) => {
                    console.log("response?.data", response?.data)
                    setIsLoading(false);
                    setFormData({
                        ...initialState
                    });
                    props?.getAllCoupons();
                    props?.setOpenAddCouponModal(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                });
        }
    };

    return (
        <React.Fragment>
            <Modal
                open={props.openAddCouponModal}
                onClose={() => {
                    props.setOpenAddCouponModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar h-[500px] md:w-[650px] overflow-y-scroll overflow-x-auto px-4">
                        <div className="btn-wrapper text-center">
                            <span
                                onClick={() => props.setOpenAddCouponModal(false)}
                                className="absolute top-6  right-5 text-3xl  cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-primaryColor font-bold">
                                Add Coupon
                            </div>
                        </div>

                        <div className="flex-auto mt-2">
                            <form onSubmit={handleAddCoupon}>
                                <div className="flex gap-8 flex-col md:flex-row">
                                    {/* Name */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Name
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="text"
                                            id="name"
                                            autoComplete="off"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            className={
                                                formData.nameErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter Coupon Name"
                                        ></input>
                                    </div>

                                    {/* Coupon Code */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Coupon Code
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="text"
                                            id="couponCode"
                                            autoComplete="off"
                                            name="couponCode"
                                            value={formData.couponCode}
                                            onChange={handleInputChange}
                                            className={
                                                formData.couponCodeErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter Coupon Code"
                                        ></input>
                                    </div>
                                </div>

                                <div className="flex gap-8 flex-col md:flex-row">
                                    {/* Discount Type */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Discount Type
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <select
                                            id="discountType"
                                            name="discountType"
                                            value={formData.discountType}
                                            onChange={handleInputChange}
                                            className={
                                                formData.discountTypeErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                        >
                                            <option value="">Select</option>
                                            <option value="percentage">Percentage</option>
                                            <option value="flat">Flat</option>
                                        </select>
                                    </div>

                                    {/* Discount Value */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Discount Value
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="number"
                                            id="discountValue"
                                            autoComplete="off"
                                            name="discountValue"
                                            value={formData.discountValue}
                                            onChange={handleInputChange}
                                            className={
                                                formData.discountValueErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter Discount Value"
                                        ></input>
                                    </div>
                                </div>

                                <div className="flex gap-8 flex-col md:flex-row">
                                    {/* Min. Bill Amount */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Min. Bill Amount
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="number"
                                            id="minBillAmount"
                                            autoComplete="off"
                                            name="minBillAmount"
                                            value={formData.minBillAmount}
                                            onChange={handleInputChange}
                                            className={
                                                formData.minBillAmountErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter Min. Bill Amount"
                                        ></input>
                                    </div>

                                </div>

                                <div className="flex gap-8 flex-col md:flex-row">
                                    {/* Allowed Per Customer */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Allowed Per Customer
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="number"
                                            id="allowedPerCustomer"
                                            autoComplete="off"
                                            name="allowedPerCustomer"
                                            value={formData?.allowedPerCustomer}
                                            onChange={handleInputChange}
                                            className={
                                                formData?.allowedPerCustomerErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter Allowed Per Customer"
                                        ></input>
                                    </div>

                                    {/* Customers */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Customers
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <select
                                            name="customers"
                                            id="customers"
                                            onChange={handleInputChange}
                                            className={
                                                formData.customersErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                        >
                                            <option value="">Select</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Old Customer">Old Customer</option>
                                            <option value="New Customer">New Customer</option>
                                            <option value="All">All</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="flex gap-8 flex-col md:flex-row">
                                    {/* Start Date */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Start Date
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="date"
                                            id="startDate"
                                            autoComplete="off"
                                            name="startDate"
                                            value={formData.startDate}
                                            onChange={handleInputChange}
                                            className={
                                                formData.startDateErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                        ></input>
                                    </div>
                                    {/* End Date */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            End Date
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="date"
                                            id="endDate"
                                            autoComplete="off"
                                            name="endDate"
                                            value={formData.endDate}
                                            onChange={handleInputChange}
                                            className={
                                                formData.endDateErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                        ></input>
                                    </div>
                                </div>

                                <div className="flex gap-8 flex-col md:flex-row">
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Description
                                        </div>
                                        <textarea
                                            id="description"
                                            autoComplete="off"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleInputChange}
                                            className={
                                                formData.descriptionErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter Description"
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="flex gap-8 flex-col md:flex-row">
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Applicable On
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <div className="mb-2 flex flex-wrap">
                                            {formData?.applicableOn?.map(option => (
                                                <span key={option} className="inline-flex items-center bg-primaryColor text-white rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">
                                                    {option}
                                                    <button onClick={() => removeApplicableOn(option)} className="ml-2 text-white ">
                                                        <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </span>
                                            ))}
                                        </div>
                                        <div className="relative" ref={applicableOnRef}>
                                            <button
                                                type="button"
                                                onClick={toggleApplicableOnDropdown}
                                                className={`bg-white w-1/2 text-left px-4 py-2 rounded border ${formData.applicableOnErr ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                            >
                                                {formData?.applicableOn?.length > 0 ? `${formData?.applicableOn?.length} selected` : 'Select options'}
                                            </button>
                                            {isApplicableOnOpen && (
                                                <div className="absolute z-10 w-1/2 mt-1 bg-white border border-gray-300 rounded shadow-lg">
                                                    {['All Service', 'All Product'].map(option => (
                                                        <label key={option} className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                            <input
                                                                type="checkbox"
                                                                checked={formData?.applicableOn?.includes(option)}
                                                                onChange={() => handleApplicableOnClick(option)}
                                                                className="form-checkbox h-5 w-5 text-blue-600"
                                                            />
                                                            <span className="ml-2">{option}</span>
                                                        </label>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <div className="w-full flex items-center justify-end mt-5 gap-8">
                                    <button
                                        type="button"
                                        onClick={() => { setFormData(initialState); props.setOpenAddCouponModal(false) }}
                                        className="py-2 px-6">
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-primaryColor text-white py-2 w-28 flex justify-center rounded hover:bg-primaryHoverColor focus:outline-none"
                                    >
                                        {isLoading ?
                                            <div className="border-white animate-spin border-y-4 w-6 h-6 rounded-full border-dashed border-t-transparent">
                                            </div>
                                            : "Add Coupon"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
            <style>
                {`
          input[type="checkbox"]{
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid gray;
            outline: none;
            cursor: pointer;
            position: relative;
          }

          input[type="checkbox"]:checked {
            border: 2px solid #fe8740;
          }

          input[type="checkbox"]:checked::after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background: #fe8740;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}</style>
        </React.Fragment>
    );
};

export default AddCoupon;
