import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import icon from '../../Assets/imgpsh_fullsize_anim.png';
import { tostifyErr, tostifySuccess } from "../Common/tostifyMessages";
import { decryptData } from "../Common/localStorageUtils";
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { IoMdNotifications } from 'react-icons/io';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const AcceptRequest = ({
    openAcceptService,
    setOpenAcceptService,
    services,
    setServices,
    setIsAvailable,
    fetchNotifications
}) => {
    const [loadingStates, setLoadingStates] = useState({});
    const token = "Bearer " + localStorage.getItem("refresh_token");

    const handleServiceAction = async (serviceId, billId, isAccepted) => {
        setLoadingStates(prev => ({ ...prev, [serviceId]: true }));

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/notification/accept-service`,
                {
                    serviceId,
                    billId
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    }
                }
            );

            const updatedServices = services.filter(s => s._id !== serviceId);
            setServices(updatedServices);
            fetchNotifications();
            if (updatedServices?.length === 0) {
                setIsAvailable(false);
                setOpenAcceptService(false);
            }

            tostifySuccess(response?.data?.message);
        } catch (error) {
            console.error('Error processing service:', error);
            tostifyErr(error?.response?.data?.message || "Error processing service");
        } finally {
            setLoadingStates(prev => {
                const newStates = { ...prev };
                delete newStates[serviceId];
                return newStates;
            });
        }
    };

    return (
        <Modal
            open={openAcceptService}
            onClose={() => setOpenAcceptService(false)}
            aria-labelledby="service-requests-modal"
        >
            <Box sx={style}>
                <div className="max-h-[500px] overflow-y-auto">
                    <div className="text-center mb-6">
                        <div className="flex justify-center items-center mb-4">
                            <IoMdNotifications className="text-5xl text-primaryColor" />
                        </div>
                        <h2 className="text-2xl font-bold text-[#2b2f32]">
                            Service Requests
                        </h2>
                    </div>

                    {services?.length === 0 ? (
                        <div className="text-center text-gray-500 p-8 bg-gray-100 rounded-lg">
                            <p className="text-xl">No pending service requests</p>
                        </div>
                    ) : (
                        services?.map((service) => (
                            <div
                                key={service?.serviceId}
                                className="bg-white border rounded-lg p-6 mb-4 shadow-md transition-all duration-300 hover:shadow-lg"
                            >
                                <h3 className="font-semibold text-lg mb-4 text-[#2b2f32]">
                                    {service.message}
                                </h3>

                                <div className="flex justify-between items-center mt-6">
                                    <button
                                        onClick={() => handleServiceAction(service?.serviceId, service.billId, false)}
                                        disabled={loadingStates[service?.serviceId]}
                                        className="flex items-center justify-center px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-400"
                                        aria-label="Reject service request"
                                    >
                                        {loadingStates[service?.serviceId] ? (
                                            <div className="w-5 h-5 rounded-full animate-spin border-2 border-dashed border-gray-500 border-t-transparent"></div>
                                        ) : (
                                            <>
                                                <FaTimesCircle className="mr-2" />
                                                <span>Reject</span>
                                            </>
                                        )}
                                    </button>

                                    <button
                                        onClick={() => handleServiceAction(service?.serviceId, service.billId, true)}
                                        disabled={loadingStates[service?.serviceId]}
                                        className="flex items-center justify-center px-4 py-2 bg-primaryColor text-white hover:bg-primaryColor/90 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-primaryColor"
                                        aria-label="Accept service request"
                                    >
                                        {loadingStates[service?.serviceId] ? (
                                            <div className="w-5 h-5 rounded-full animate-spin border-2 border-dashed border-white border-t-transparent"></div>
                                        ) : (
                                            <>
                                                <FaCheckCircle className="mr-2" />
                                                <span>Accept</span>
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </Box>
        </Modal>
    );
};

export default AcceptRequest;

