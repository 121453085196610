/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { AnalyticsHeader } from "./AnalyticsHeader";
import { AnalyticsGraphsView } from "./AnalyticsGraphsView";
import { AnalyticsTablesView } from "./AnalyticsTablesView";
import { useDispatch, useSelector } from "react-redux";
import { allAppointmentList } from "../../state-management/main/actions";
import { getAppointments } from "../../Screens/Common/initData";
import { decryptData } from "../../Screens/Common/localStorageUtils";
import axios from "axios";
import { getEncryptItems } from "../../Screens/Common/localStorageUtils";

const AnalyticsView = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Analytics`;
  const [isGraphView, setIsGraphView] = useState(false);
  const [dailyRevenueData, setDailyRevenueData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStaff, setActiveStaff] = useState([]);
  const [inactiveStaff, setInActiveStaff] = useState([]);
  const branch = getEncryptItems("branchDetails");
  const admin = useSelector((state) => state.main);
  const adminId = decryptData()?.userTypeData?._id;
  const checkLaserList = admin?.laserList || [];
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const staffVsServiceData =
    useSelector((state) => state?.main?.serviceVsStaffData)?.filter(
      (ele) => ele?.organizationId === branch?._id
    ) || [];
  let last7DaysAppointments;

  const dispatch = useDispatch();
  const decryptedData = decryptData();
  const AdminId = decryptedData?.userTypeData?._id;
  const Admin = useSelector((state) => state.main);
  const filteredAppointments =
    Admin?.appointmentList?.filter(
      (item) => item.organization.adminId === AdminId
    ) || [];
  let paymentsAnalyticsData = {};
  const membershipRevenueAnalyticsData = {};
  const serviceVsStaffAnalyticsData = {};

  const currentDate = new Date();
  const dateBefore = new Date(currentDate);
  dateBefore.setDate(currentDate.getDate() - 7);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const formattedCurrentDate = formatDate(currentDate);
  const formattedDateBeforeSevenDay = formatDate(dateBefore);
  const [selectedFilter, setSelectedFilter] = useState("All Filter");

  const filterOptions = [
    "All Filter",
    "Daily Revenue Filter",
    "Appointments Filter",
    "Appointments Status",
    "Staff Status Filter",
    "Payments Filter",
    "Membership Filter",
    "Employee Vs Service Filter",
  ];

  const [from, setFrom] = useState(formattedCurrentDate);
  const [to, setTo] = useState(formattedCurrentDate);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  useEffect(() => {
    DailyAppointmentsAnalyticData();
    fetchStaffDetails();
  }, []);

  // Parse the date
  const parseDate = (dateString) => {
    const parts = dateString.match(/(\d{4})-(\d{2})-(\d{2})/);
    if (parts) {
      return parts[3] + "-" + parts[2] + "-" + parts[1];
    }
    return dateString;
  };

  // filter date from "From date" to "TO date" start
  let startDate = new Date(from).toISOString()?.split("T")[0];
  let endDate = new Date(to).toISOString()?.split("T")[0];
  const filterDataByDate = (data, startDate, endDate, dateInData) => {
    if (from === "" && to === "") {
      return;
    }
    const filteredData = {};
    if (dateInData === "appointmentDate") {
      for (const day in data) {
        const filteredEntries = data[day]?.filter((entry) => {
          const entryDate = new Date(entry.appointmentDate);
          const isoDate = entryDate?.toISOString()?.slice(0, 10);
          return isoDate >= startDate && isoDate <= endDate;
        });
        if (filteredEntries.length > 0) {
          filteredData[day] = filteredEntries;
        }
      }
    }
    if (dateInData === "date") {
      data?.forEach((entry) => {
        const day = entry.date;
        const [dayStr, monthStr, yearStr] = entry?.date?.split("-");
        const isoDate = `${yearStr}-${monthStr}-${dayStr}`;
        if (isoDate >= startDate && isoDate <= endDate) {
          if (!filteredData[day]) {
            filteredData[day] = [];
          }
          filteredData[day].push(entry);
        }
      });
    }
    if (dateInData === "createdAt") {
      data?.forEach((entry) => {
        const day = entry?.dayName;
        if (entry?.date) {
          const [dayStr, monthStr, yearStr] = entry?.date?.split("-");
          const isoDate = `${yearStr}-${monthStr}-${dayStr}`;
          if (isoDate >= startDate && isoDate <= endDate) {
            if (!filteredData[day]) {
              filteredData[day] = [];
            }
            filteredData[day]?.push(entry);
          }
        }
      });
    }
    return filteredData;
  };
  // end filter

  // MembershipRevenue data of Table view and Graph view start
  const purchaseRevenueList = Admin?.membershipRevenueList?.filter(
    (rev) => rev?.organizationId === branch?._id
  );
  const addedPurchaseRevenueList = purchaseRevenueList?.filter(async (item) => {
    const itemDate = new Date(item.date);
    if (itemDate <= currentDate) {
      item.dayName = days[itemDate.getDay()];
      item.date = await parseDate(item?.date);
      return true;
    }
    return false;
  });
  let afterFilterDataMembership = filterDataByDate(
    addedPurchaseRevenueList,
    startDate,
    endDate,
    "date"
  );
  const serviceVsStaffList = staffVsServiceData?.filter(async (item) => {
    const itemDate = new Date(item?.createdAt);
    if (itemDate <= currentDate) {
      item.dayName = days[itemDate.getDay()];
      item.date = await parseDate(item?.createdAt);
      return true;
    }
    return false;
  });
  let afterFilteredServiceVsStaffData = filterDataByDate(
    serviceVsStaffList,
    startDate,
    endDate,
    "createdAt"
  );
  for (const [day, data] of Object.entries(afterFilteredServiceVsStaffData)) {
    serviceVsStaffAnalyticsData[day] = data;
  }
  for (const [day, data] of Object.entries(afterFilterDataMembership)) {
    membershipRevenueAnalyticsData[day] = data;
  }
  // MembershipRevenue data of Table view and Graph view end

  // Payments data of Table view and Graph view start
  const checkLaser = admin?.laserList || [];
  const ComibieAllLasers = checkLaser
    ?.filter((ele) => ele?.organizationId === branch?._id)
    ?.map((item) => item?.checkLaser)
    .flat();

  const last7DaysData = ComibieAllLasers?.filter(async (item) => {
    const itemDate = new Date(item.date);
    if (itemDate <= currentDate) {
      item.dayName = days[itemDate.getDay()];
      item.date = await parseDate(item?.date);
      return true;
    }
    return false;
  });

  let dailySumsForPaymentsResult = filterDataByDate(
    last7DaysData,
    startDate,
    endDate,
    "date"
  );

  Object.keys(dailySumsForPaymentsResult)?.forEach((day) => {
    const dayData = dailySumsForPaymentsResult[day];
    const dayName = dayData[0].dayName;
    paymentsAnalyticsData[dayName] = [];
    dayData?.forEach((item) => {
      const { card, upi, cash, expense, total, subTotal, date, dayName } = item;
      paymentsAnalyticsData[dayName].push({
        card,
        upi,
        cash,
        expense,
        total,
        subTotal,
        date,
        dayName,
      });
    });
  });

  const fetchStaffDetails = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/analytics/all-staff-status`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    try {
      const res = await axios.request(config);
      setActiveStaff(
        res?.data?.activeStaff?.filter((stf) => stf.adminId === adminId)
      );
      setInActiveStaff(
        res?.data?.inactiveStaff?.filter((stf) => stf?.adminId === adminId)
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const DailyAppointmentsAnalyticData = async () => {
    const data = await getAppointments();
    dispatch(allAppointmentList(data));
  };

  const formatAndGroupAppointments = (appointments) => {
    if (!Array.isArray(appointments)) {
      console.error("Appointments is not an array:", appointments);
      return {};
    }
    // const days = [
    //   "Sunday",
    //   "Monday",
    //   "Tuesday",
    //   "Wednesday",
    //   "Thursday",
    //   "Friday",
    //   "Saturday",
    // ];
    const dayAppointments = {};
    for (const appointment of appointments) {
      const createdAt = new Date(appointment?.appointmentDate);
      if (true) {
        // if (daysDifference <= 7) {
        const formattedDate = createdAt?.toISOString()?.split("T")[0];
        // const dayName = days[createdAt?.getDay()];

        const formattedAppointment = {
          createdAt,
          date: formattedDate,
          organizationId: appointment?.organization,
          staffId: appointment?.staffId,
          customerName: appointment?.customerName,
          customerPhone: appointment?.customerPhone,
          appointmentDate: appointment?.appointmentDate,
        };
        dayAppointments[formattedDate] = dayAppointments[formattedDate] || [];
        dayAppointments[formattedDate].push(formattedAppointment);
      }
    }

    // const result = {};
    // days?.forEach((day) => {
    //   if (dayAppointments[day]) {
    //     result[day] = dayAppointments[day]?.sort(
    //       (a, b) => a.createdAt - b.createdAt
    //     );
    //   }
    // });

    return dayAppointments;
  };
  let appointmentData = formatAndGroupAppointments(filteredAppointments);

  last7DaysAppointments = filterDataByDate(
    appointmentData,
    startDate,
    endDate,
    "appointmentDate"
  );

  useEffect(() => {
    const branch = getEncryptItems("branchDetails");

    if (checkLaserList.length > 0) {
      const organization = checkLaserList?.find(
        (item) => item?.organizationId === branch?._id
      );
      const convertDayName = (dayName) => {
        switch (dayName) {
          case "Sunday":
            return "Sun";
          case "Monday":
            return "Mon";
          case "Tuesday":
            return "Tue";
          case "Wednesday":
            return "Wed";
          case "Thursday":
            return "Thu";
          case "Friday":
            return "Fri";
          case "Saturday":
            return "Sat";
          default:
            return dayName;
        }
      };

      const newData = organization?.checkLaser?.map((item) => {
        item.dayName = convertDayName(item?.dayName);
        return item;
      });

      let DailyRevenueResult = filterDataByDate(
        newData,
        startDate,
        endDate,
        "date"
      );

      setDailyRevenueData(DailyRevenueResult);
    }
  }, [checkLaserList, from, to]);

  return (
    <React.Fragment>
      <AnalyticsHeader
        setIsGraphView={setIsGraphView}
        isGraphView={isGraphView}
        formattedDateBeforeSevenDay={formattedDateBeforeSevenDay}
        formattedCurrentDate={formattedCurrentDate}
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        filterOptions={filterOptions}
      />

      {isGraphView ? (
        <AnalyticsGraphsView
          dailyRevenueData={dailyRevenueData}
          last7DaysAppointments={last7DaysAppointments}
          isLoading={isLoading}
          activeStaff={activeStaff}
          inactiveStaff={inactiveStaff}
          dailySumsForPayments={paymentsAnalyticsData}
          dailyServiceVsStaff={serviceVsStaffAnalyticsData}
          dailyRevenueFromMembership={membershipRevenueAnalyticsData}
          selectedFilter={selectedFilter}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
        />
      ) : (
        <AnalyticsTablesView
          dailyRevenueData={dailyRevenueData}
          last7DaysAppointments={last7DaysAppointments}
          isLoading={isLoading}
          activeStaff={activeStaff}
          inactiveStaff={inactiveStaff}
          dailySumsForPayments={paymentsAnalyticsData}
          dailyServiceVsStaff={serviceVsStaffAnalyticsData}
          dailyRevenueFromMembership={membershipRevenueAnalyticsData}
          selectedFilter={selectedFilter}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
        />
      )}
    </React.Fragment>
  );
};

export default AnalyticsView;
