import React, { useEffect, useState } from 'react';
import axios from 'axios';
import add from '../../Assets/AddFrame(1).png'
import { ImSearch } from 'react-icons/im';
import DownloadTableData from '../../Screens/Common/downloadData';
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import AddCoupon from './MembershipModals/AddCoupon';
import EditCoupon from './MembershipModals/EditCoupon';
import SkeletonTableLoader from '../../Screens/Common/Loading/SkeletonTableLoader';
import { useSelector } from 'react-redux';
import useDebounce from '../../Screens/Common/useDebounce';

const CuponTable = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [openAddCouponModal, setOpenAddCouponModal] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [openEditCouponModal, setOpenEditCouponModal] = useState(false);
    const [editableCoupon, setEditableCoupon] = useState({});
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const branch = useSelector((state) => state?.main?.branch)?._id;
    const token = `Bearer ${localStorage.getItem('refresh_token')}`;
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const itemsPerPage = 5;

    // Table headers and row data mapping
    const headerData = [
        { label: 'Name', field: 'Name' },
        { label: 'Coupon Code', field: 'CouponCode' },
        { label: 'Discount', field: 'Discount' },
        { label: 'Description', field: 'Description' },
        { label: 'Offer Period', field: 'Offer_Period' },
        { label: 'MinBill/MaxDiscount', field: 'Min_Bill_Max_discount' },
        { label: 'Actions', field: 'Actions' },
    ];

    const rowData = coupons?.map((data) => ({
        Name: data?.name,
        CouponCode: data?.couponCode,
        Description: data?.description || '-----',
        Discount: `${data?.discountType} / ${data?.discountValue}`,
        Offer_Period: `${data?.startDate} - ${data?.endDate}`,
        Min_Bill_Max_discount: `${data?.minAmount} / ${data?.maxDiscount}`,
        Actions: data?._id,
    }));

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleOpenEdit = (id) => {
        const currentCoupon = coupons.find((ele) => ele?._id === id);
        setEditableCoupon(currentCoupon);
        setOpenEditCouponModal(true);
    };

    const getAllCoupons = async () => {
        if (!branch) return;

        setIsLoading(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/coupon/find-coupons/${branch}`,
                {
                    headers: { Authorization: token },
                    params: {
                        page: currentPage,
                        limit: itemsPerPage,
                        search: debouncedSearchTerm,
                        startDate,
                        endDate,
                    },
                }
            );

            const { coupons: fetchedCoupons, totalPages: total } = response?.data?.data;
            setCoupons(fetchedCoupons);
            setTotalPages(total);
        } catch (error) {
            console.error('Error fetching coupons:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllCoupons();
    }, [branch, currentPage, debouncedSearchTerm, startDate, endDate]);

    return (
        <React.Fragment>
            <AddCoupon
                openAddCouponModal={openAddCouponModal}
                setOpenAddCouponModal={setOpenAddCouponModal}
                getAllCoupons={getAllCoupons}
            />
            {editableCoupon && (
                <EditCoupon
                    openEditCouponModal={openEditCouponModal}
                    setOpenEditCouponModal={setOpenEditCouponModal}
                    editableCoupon={editableCoupon}
                    handleOpenEdit={handleOpenEdit}
                    getAllCoupons={getAllCoupons}
                />
            )}

            <div className="mx-auto mt-4">
                <div className="px-5 py-5 bg-white flex items-center justify-between rounded-t-lg">
                    <div className="font-bold text-2xl">Coupons</div>
                    <div className="flex items-center gap-4">
                        <div className="bg-white rounded-lg w-full xs:w-72 flex p-1 border">
                            <span className="flex items-center justify-center ml-2">
                                <ImSearch color="gray" size={15} />
                            </span>
                            <input
                                id="searchData"
                                className="border-0 px-4 py-1 placeholder-gray-500 text-sm focus:outline-none w-full"
                                placeholder="Search Coupons"
                                type="text"
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(1);
                                    setSearchTerm(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex gap-2">
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="px-2 py-1 border rounded"
                            />
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="px-2 py-1 border rounded"
                            />
                        </div>
                        <DownloadTableData
                            rowData={rowData}
                            headerData={headerData}
                        />

                        <>
                            <button
                                onClick={() => setOpenAddCouponModal(true)}
                            >
                                <span className="flex justify-center items-center">
                                    <img
                                        src={add}
                                        alt="add"
                                        className="text-primaryColor w-7 h-7"
                                    />
                                </span>
                            </button>
                        </>
                    </div>
                </div>

                {/* Table */}
                {isLoading ? (
                    <SkeletonTableLoader />
                ) : (
                    <div className="shadow rounded-lg overflow-x-auto">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr className="bg-gray-100">
                                    {headerData?.map((header, index) => (
                                        <th
                                            key={index}
                                            className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600"
                                        >
                                            {header.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rowData?.map((row, index) => (
                                    <tr key={index}>
                                        {headerData.map((header, i) => (
                                            <td
                                                key={i}
                                                className="px-5 py-5 border border-gray-200 bg-white text-sm"
                                            >
                                                {header.field === 'Actions' ? (
                                                    <div className="flex">
                                                        <RiEdit2Fill
                                                            size={15}
                                                            onClick={() => handleOpenEdit(row[header.field])}
                                                            className="mr-4 text-gray-600 hover:text-gray-800 cursor-pointer"
                                                        />
                                                        <RiDeleteBin5Fill
                                                            size={15}
                                                            className="text-red-500 hover:text-red-700 cursor-pointer"
                                                        />
                                                    </div>
                                                ) : (
                                                    row[header.field]
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* Pagination */}
                {coupons.length > 0 && (
                    <div className="px-5 py-5 bg-white border-t flex justify-between items-center">
                        <span className="text-sm">
                            Showing {currentPage} of {totalPages} Pages
                        </span>
                        <div className="inline-flex">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="px-4 py-2 bg-gray-200 rounded-l disabled:opacity-50"
                            >
                                Prev
                            </button>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="px-4 py-2 bg-gray-200 rounded-r disabled:opacity-50"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default CuponTable;
