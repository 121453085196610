import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { ImSearch } from "react-icons/im";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { LuEye } from "react-icons/lu";

import {
  decryptData,
  getEncryptItems
} from "../../Screens/Common/localStorageUtils";
import {
  allBillList,
  storeClient,
  storeDraftBill
} from "../../state-management/main/actions";

import Dropdown from "../../Screens/Common/Dropdown";
import SkeletonTableLoader from "../../Screens/Common/Loading/SkeletonTableLoader";
import InvoiceDeleteModal from "./InvoiceModal/InvoiceDeleteModal";
import InvoiceViewModal from "./InvoiceModal/InvoiceViewModal";
import InvoiceDownload from "./InvoiceDownload/InvoiceDownload";
import NewLoader from "../../Screens/Common/NewLoader";
import pdf from "../../Assets/Pdf.png";
import clear from "../../Assets/billClear.png";

import { downloadInvoicesAsPDF } from "./InvoiceDownload/downloadInvoiceasPdf";
import { allBillData } from "../../Screens/Common/initData";

const AllInvoice = (props) => {
  const [billingData, setBillingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totlaItems, setTotalItems] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("Filter Invoices");
  const [isSelected, setIsSelected] = useState("Today");
  const [searchInput, setSearchInput] = useState("");
  const [startFrom, setStartFrom] = useState("");
  const [endFrom, setEndFrom] = useState("");
  const [singleInvoice, setSingleInvoice] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState("");

  const dispatch = useDispatch();
  const branch = useSelector((state) => state?.main?.branch);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const getBillData = async () => {
    const data = await allBillData();
    dispatch(allBillList(data?.filter(ele => !ele?.isDeleted)));
  };

  const branchName = branch?.name || "";
  const branchLocation = branch?.address || "";
  const branchPhone = branch?.primaryPhone || "";
  const branchPic = branch?.url;

  const hasPermission = (permission) => {
    return (
      userRole === "admin" ||
      (userRole === "staff" && userRoleName?.roleId?.permissions?.dashboard?.[permission])
    );
  };

  const fetchBillingData = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = {
        organizationId: branch?._id,
        page: currentPage,
        limit: 5,
        search: searchInput,
        staffId: userRole === "staff" ? decryptData()?.userTypeData?._id : "",
      };

      // Date filtering
      if (isSelected === "Today") {
        params.dateFilter = "today";
      } else if (isSelected === "YesterDay") {
        params.dateFilter = "yesterday";
      } else if (isSelected === "Month") {
        params.dateFilter = "month";
      } else if (isSelected === "Custom Date" && startFrom && endFrom) {
        params.startDate = startFrom;
        params.endDate = endFrom;
      }

      // Membership and invoice type filtering
      switch (selectedFilter) {
        case "Invoice With Membership":
          params.hasMembership = "true";
          break;
        case "Invoice With No Membership":
          params.hasMembership = "false";
          break;
        case "Deleted Invoice":
          params.isDeleted = "true";
          break;
        default:
          params.isDeleted = "false";
      }

      const config = {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/billing//billing-by-role`,
        headers: { Authorization: token },
        params: params,
      };

      const response = await axios.request(config);

      setBillingData(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
      setTotalItems(response?.data?.total);
      // dispatch(allBillList(response.data.data));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching billing data:", error);
      setIsLoading(false);
      setBillingData([]);
    }
  }, [
    branch?._id,
    currentPage,
    searchInput,
    isSelected,
    startFrom,
    endFrom,
    selectedFilter,
    token,
    dispatch
  ]);

  useEffect(() => {
    if (branch?._id) {
      fetchBillingData();
    }
  }, [fetchBillingData, branch?._id]);

  const fetchClient = async (phone) => {
    if (phone) {
      setIsLoading(true);
      try {
        const config = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/customer/singleCustomer?phone=${phone}&branch=${branch?._id}`,
          headers: { 'Authorization': token },
        };

        const response = await axios.request(config);
        dispatch(storeClient(response?.data?.data?.customer));
        return response?.data?.data;
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const openViewModal = async (id) => {
    const selectedInvoice = billingData?.find((item) => item?._id === id);
    await fetchClient(`+91${selectedInvoice?.billingInfo?.phone}`);
    dispatch(storeDraftBill(selectedInvoice));
    setSingleInvoice(selectedInvoice);
    setViewModal(true);
  };

  const handleSelectDateType = (value) => {
    setIsSelected(value || "");
    setCurrentPage(1);
  };

  const handleSelectMembershipType = (value) => {
    setSelectedFilter(value || "");
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchInput(searchValue);
    setCurrentPage(1);
  };

  const handleClear = () => {
    setSearchInput("");
    setStartFrom("");
    setEndFrom("");
    setIsSelected("All");
    setSelectedFilter("Filter Invoices");
    setCurrentPage(1);
  };

  return (
    <React.Fragment>
      {(userRole === "admin" ||
        (userRole === "staff" &&
          userRoleName?.roleId?.permissions?.invoice?.sidebarInvoice)) && (
          <div className="mt-4 mx-auto">
            {/* Filter and Search Section */}
            <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg flex flex-wrap justify-between gap-3">
              <div className="bg-white rounded-lg flex p-1 relative border border-gray-200">
                <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                  <ImSearch color="gray" size={15} />
                </span>
                <input
                  className="border-0 rounded px-8 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                  placeholder="Search By Name, Phone, or Invoice"
                  type="text"
                  value={searchInput}
                  onChange={handleSearch}
                />
              </div>
              {/* Date Filter Dropdown */}
              <div className="flex flex-wrap items-center gap-2">
                <Dropdown
                  label="Today"
                  items={["Today", "YesterDay", "Month", "Custom Date"]}
                  onSelect={handleSelectDateType}
                />
                {isSelected === "Custom Date" && (
                  <div className="flex flex-wrap gap-2">
                    {/* From Date Input */}
                    <div className="flex justify-center items-center gap-6">
                      <label>From Date</label>
                      <input
                        value={startFrom}
                        onChange={(e) => {
                          setStartFrom(e.target.value);
                          setCurrentPage(1);
                        }}
                        type="date"
                        className="p-2 h-11 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6]"
                      />
                    </div>
                    {/* To Date Input */}
                    <div className="flex justify-center items-center gap-6">
                      <label>To Date</label>
                      <input
                        value={endFrom}
                        onChange={(e) => {
                          setEndFrom(e.target.value);
                          setCurrentPage(1);
                        }}
                        type="date"
                        className="p-2 h-11 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6]"
                      />
                    </div>
                    {/* Clear Button */}
                    <button onClick={handleClear}>
                      <img src={clear} alt="clear" className="w-8 h-8" />
                    </button>
                  </div>
                )}
              </div>

              {/* Invoice Filter Dropdown */}
              <Dropdown
                label="Filter Invoices"
                items={userRole === "admin" ? [
                  "Filter Invoices",
                  "Invoice With Membership",
                  "Invoice With No Membership",
                  "Deleted Invoice",
                ] : [
                  "Filter Invoices",
                  "Invoice With Membership",
                  "Invoice With No Membership",
                ]}
                onSelect={handleSelectMembershipType}
              />

              {/* PDF Download */}
              <div>
                <img
                  src={pdf}
                  alt="download"
                  onClick={() => downloadInvoicesAsPDF(
                    billingData,
                    branchPic,
                    branchName,
                    branchLocation,
                    branchPhone
                  )}
                  className="w-7 h-7 cursor-pointer"
                />
              </div>
            </div>

            {/* Table Section */}
            {isLoading ? (
              <SkeletonTableLoader />
            ) : billingData?.length === 0 ? (
              <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
                No Invoices Found
              </div>
            ) : (
              <>
                <div className="shadow rounded-t-lg overflow-x-auto">
                  <table className="min-w-full leading-normal">
                    {/* Table Header */}
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Sl
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide text-center">
                          ID
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Client Detail
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Service Used
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Product Sold
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Billed
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Status
                        </th>
                        {(hasPermission("invoiceEdit") ||
                          hasPermission("invoiceDelete") ||
                          hasPermission("invoiceView")) && (
                            <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                              Actions
                            </th>
                          )}
                      </tr>
                    </thead>

                    {/* Table Body */}
                    <tbody>
                      {billingData?.map((ele, ind) => (
                        <tr
                          key={ele._id}
                          className={`${ele?.isDeleted
                            ? 'hover:bg-gray-100 border-b border-white-200 opacity-50'
                            : 'bg-white text-gray-900'
                            }`}
                        >
                          {/* Sl No */}
                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="whitespace-no-wrap text-center">
                              {(currentPage - 1) * 5 + ind + 1}
                            </div>
                          </td>

                          {/* Invoice Number */}
                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="whitespace-no-wrap text-center">
                              {ele?.invoiceNumber}{' '}
                              {ele?.isDraftInvoice && (
                                <span className="text-primaryColor text-xs">(Draft)</span>
                              )}
                            </div>
                          </td>

                          {/* Client Details */}
                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="whitespace-no-wrap text-center">
                              <h1>{ele?.billingInfo?.name}</h1>
                              <h1>{ele?.billingInfo?.phone}</h1>
                            </div>
                          </td>

                          {/* Services Used */}
                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="whitespace-no-wrap text-center">
                              {ele?.services?.map((service, index) => (
                                <div key={index}>
                                  <p>{service?.serviceName}</p>
                                  <p>{service?.duration}</p>
                                </div>
                              ))}
                            </div>
                          </td>

                          {/* Products Sold */}
                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="whitespace-no-wrap text-center">
                              {ele?.products?.map((product, index) => (
                                <div key={index}>
                                  <p>{product?.productName}</p>
                                  <p>{product?.duration}</p>
                                </div>
                              ))}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="whitespace-no-wrap text-center">{ele?.paidAmount}</div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="whitespace-no-wrap flex items-center justify-center">
                              {ele?.status || 'Paid'}
                            </div>
                          </td>

                          {
                            (hasPermission("invoiceEdit") ||
                              hasPermission("invoiceDelete") ||
                              hasPermission("invoiceView")) && (
                              <td className="px-5 py-5 border border-gray-200 text-sm">
                                <div className="whitespace-no-wrap flex gap-3 items-center justify-center">

                                  {hasPermission("invoiceDelete") && !ele?.isDeleted && (
                                    <RiDeleteBin5Fill
                                      className="text-red-500 hover:text-primaryHoverColor transition duration-300 cursor-pointer"
                                      onClick={() => {
                                        setId(ele?._id);
                                        setOpenModal(true);
                                      }}
                                    />
                                  )}

                                  {hasPermission("invoiceEdit") && (
                                    <InvoiceDownload id={ele?._id} className="cursor-pointer" />
                                  )}

                                  {hasPermission("invoiceView") ? (
                                    isLoading ? (
                                      <NewLoader />
                                    ) : (
                                      <LuEye
                                        className="cursor-pointer w-6 h-6 text-orange-500"
                                        onClick={() => {
                                          openViewModal(ele?._id);
                                          setId(ele?._id);
                                        }}
                                      />
                                    )
                                  ) : null}
                                </div>
                              </td>
                            )
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {/* Pagination Section */}
            {billingData?.length > 0 && (
              <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
                <span className="text-xs xs:text-sm text-gray-900">
                  Showing {(currentPage - 1) * 5 + 1} to{" "}
                  {Math.max((currentPage - 1) * 5 + billingData.length, billingData.length)}
                  {" "}of {totlaItems} Entries
                </span>
                <div className="inline-flex mt-2 xs:mt-0">
                  <button
                    onClick={() => setCurrentPage(prevPage => prevPage - 1)}
                    disabled={currentPage === 1}
                    className={`text-sm ${currentPage === 1
                      ? "bg-gray-200 cursor-not-allowed"
                      : "bg-gray-300 hover:bg-gray-400 cursor-pointer"
                      } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                  >
                    Prev
                  </button>
                  <button
                    onClick={() => setCurrentPage(prevPage => prevPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`text-sm ${currentPage === totalPages
                      ? "bg-gray-200 cursor-not-allowed"
                      : "bg-gray-300 hover:bg-gray-400 cursor-pointer"
                      } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

      <InvoiceDeleteModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={id}
        setId={setId}
        getBillData={getBillData}
        FetchBillingData={fetchBillingData}
      />

      {singleInvoice && (
        <InvoiceViewModal
          viewModal={viewModal}
          setViewModal={setViewModal}
          AllBills={props?.AllBills}
          singleInvoice={singleInvoice}
          id={id}
          setId={setId}
          FetchBillingData={fetchBillingData}
          getBillData={getBillData}
        />
      )}
    </React.Fragment>
  );
};

export default AllInvoice;
