import React, { useState, useEffect } from "react";
import { ReportsDynamicTable } from "../ReportsDynamicTable";
import ReportFilterType from "../ReportFilterType";
import { useSelector } from "react-redux";
import Dropdown from "../../../Screens/Common/Dropdown";
import DownloadReport from "../DownloadReport";
import {
  fetchServiceVsStaffReport,
  fetchProductVsStaffReport,
  getStaffPerformance,
} from "../ReportsData/reportData";
import SkeletonTableLoader from "../../../Screens/Common/Loading/SkeletonTableLoader";

const StaffReport = () => {
  const [selectedCategory, setSelectedCategory] = useState("month");
  const [subcategory, setSubcategory] = useState("staffByServices");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);

  const branch = useSelector((state) => state?.main?.branch);
  const staffList = useSelector((state) => state?.main?.staffList)?.filter(
    (item) => item?.organizationId === branch?._id
  );

  useEffect(() => {
    const shouldFetchData =
      selectedCategory !== "customRange" ||
      (startDate && endDate);

    if (shouldFetchData) {
      fetchReportData();
    }
  }, [subcategory, selectedCategory, startDate, endDate]);

  const fetchReportData = async (selectedStaffId = null) => {
    if (!branch?._id) return;

    setLoading(true);
    try {
      let data = [];
      const filterType = selectedCategory === "customRange"
        ? "customRange"
        : selectedCategory;

      if (subcategory === "staffByServices") {
        data = await fetchServiceVsStaffReport(
          branch._id,
          startDate,
          endDate,
          filterType
        );
      } else if (subcategory === "staffByProduct") {
        data = await fetchProductVsStaffReport(
          branch._id,
          startDate,
          endDate,
          filterType
        );
      } else if (subcategory === "staffPerformance") {
        const performanceResponse = await getStaffPerformance(
          branch._id,
          selectedStaffId,
          startDate,
          endDate,
          filterType
        );

        // Transform the data for the table
        data = performanceResponse?.reduce((acc, item) => {
          const existingStaff = acc?.find(
            staff => staff?.staffName === item.staffName &&
              staff.serviceName === item.serviceName
          );

          if (existingStaff) {
            existingStaff.totalServiceCount += item.totalServiceCount;
            existingStaff.totalRevenue += item.totalRevenue;
            existingStaff.serviceDates.push(item.serviceDate);
          } else {
            acc.push({
              staffName: item.staffName,
              serviceName: item.serviceName,
              totalServiceCount: item.totalServiceCount,
              totalRevenue: item.totalRevenue,
              serviceDates: [item.serviceDate]
            });
          }

          return acc;
        }, []).map(staff => ({
          ...staff,
          serviceDates: staff.serviceDates.join(", ")
        }));
      }

      setReportData(data || []);
    } catch (error) {
      console.error("Error fetching report data:", error);
      setReportData([]);
    } finally {
      setLoading(false);
    }
  };

  const getTableProps = () => {
    switch (subcategory) {
      case "staffByServices":
        return {
          headerData: [
            { label: "Staff", field: "staffName" },
            { label: "Service Count", field: "serviceCount" },
            { label: "Total Revenue", field: "totalRevenue" },
            { label: "Discounted Revenue", field: "totalDiscountedRevenue" }
          ],
          rowData: reportData
        };
      case "staffPerformance":
        return {
          headerData: [
            { label: "Staff Name", field: "staffName" },
            { label: "Service Name", field: "serviceName" },
            { label: "Total Service Count", field: "totalServiceCount" },
            { label: "Total Revenue", field: "totalRevenue" },
            { label: "Service Dates", field: "serviceDates" }
          ],
          rowData: reportData
        };
      case "staffByProduct":
        return {
          headerData: [
            { label: "Staff", field: "staffName" },
            { label: "Product Count", field: "productCount" },
            { label: "Total Revenue", field: "totalRevenue" },
            { label: "Discounted Revenue", field: "totalDiscountedRevenue" }
          ],
          rowData: reportData
        };
      default:
        return {
          headerData: [],
          rowData: []
        };
    }
  };

  const { headerData, rowData } = getTableProps();

  return (
    <React.Fragment>
      <div className="px-5 py-5 bg-white min-w-full border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4 mt-6">
        <div className="font-bold text-2xl tracking-wide lg:block hidden">
          Reports
        </div>

        <ReportFilterType
          setSelectedCategory={setSelectedCategory}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          subcategory={subcategory}
        />

        <Dropdown
          label="staffByServices"
          items={[
            "staffByServices",
            "staffByProduct",
            "staffPerformance"
          ]}
          onSelect={setSubcategory}
        />

        {/* Staff Dropdown for Staff Performance */}
        {subcategory === "staffPerformance" && (
          <Dropdown
            label="Select Staff"
            items={[
              "All Staff",
              ...staffList.map(staff =>
                `${staff.userId.firstName} ${staff.userId.lastName || ''}`
              )
            ]}
            onSelect={(selectedStaff) => {
              const matchedStaff = selectedStaff === "All Staff"
                ? null
                : staffList.find(
                  staff =>
                    `${staff.userId.firstName} ${staff.userId.lastName || ''}` === selectedStaff
                );

              const staffId = matchedStaff ? matchedStaff._id : null;

              fetchReportData(staffId);
            }}
          />
        )}

        <DownloadReport
          rowData={rowData}
          headerData={headerData}
        />
      </div>

      {loading ? (
        <div className="text-center py-4"><SkeletonTableLoader /></div>
      ) : (
        <>
          {(subcategory !== "" && rowData.length > 0) && (
            <ReportsDynamicTable
              rowData={rowData}
              headerData={headerData}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default StaffReport;