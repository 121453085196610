import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { decryptData, getEncryptItems } from '../../Screens/Common/localStorageUtils';
import { useSelector } from 'react-redux';
import DownloadTableData from '../../Screens/Common/downloadData';
import SkeletonTableLoader from '../../Screens/Common/Loading/SkeletonTableLoader';
import Dropdown from '../../Screens/Common/Dropdown';
import cross from '../../Assets/billClear.png'

const StockLedger = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [stockType, setStockType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("TODAY");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [ledgers, setLedgers] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
  const staffList = useSelector(state => state?.main?.staffList)?.filter(ele => ele?.organizationId === branch?._id);
  const token = "Bearer " + localStorage.getItem("refresh_token");

  // Fetch product ledger whenever filters change
  useEffect(() => {
    getProductLedger();
  }, [branch, currentPage, selectedCategory, stockType, createdBy, from, to])

  const getProductLedger = () => {
    setIsLoading(true);

    // Construct query parameters
    const params = {
      page: currentPage,
      limit: 10,
      organizationId: branch?._id,
      category: selectedCategory,
    };

    if (stockType) params.stockType = stockType;
    if (createdBy) params.createdBy = createdBy;
    if (from) params.startDate = from;
    if (to) params.endDate = to;

    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/stockLedger/all-product`,
      headers: {
        'Authorization': token
      },
      params: params
    };

    axios.request(config)
      .then((response) => {
        const { items, totalItems, currentPage, totalPages } = response?.data?.data;
        setLedgers(items);
        setTotalPages(totalPages);
        setTotalItems(totalItems);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  // Handlers for dropdowns and filters
  const handleSelectCategory = (value) => {
    setSelectedCategory(value);
    setCurrentPage(1); // Reset to first page when category changes
  };

  const handleClearCategory = () => {
    setSelectedCategory(null);
    setCurrentPage(1);
  };

  const handleStockTypeSelect = (value) => {
    setStockType(value);
    setCurrentPage(1); // Reset to first page when stock type changes
  };

  const handleClearStockType = () => {
    setStockType(null);
    setCurrentPage(1);
  };

  const handleSelectCreatedBy = (value) => {
    setCreatedBy(value);
    setCurrentPage(1); // Reset to first page when created by changes
  };


  const handleClearCreatedBy = () => {
    setCreatedBy(null);
    setCurrentPage(1);
  };

  // Reset date filters
  const handleClearDateFilter = () => {
    setFrom("");
    setTo("");
    setCurrentPage(1);
  };

  // Pagination handler
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Prepare data for download
  const headerData = [
    { label: "Sl", field: "sl" },
    { label: "Product Name", field: "productName" },
    { label: "Type", field: "type" },
    { label: "Stock Type", field: "stockType" },
    { label: "Quantity", field: "quantity" },
    { label: "Created By", field: "createdBy" },
    { label: "Date", field: "date" },
    { label: "Stock Before/After", field: "stockBeforeAfter" }
  ];

  const rowData = ledgers?.map((data, index) => ({
    stockBeforeAfter: `${data?.beforeStock} / ${data?.afterStock}`,
    createdBy: data?.createdBy?.name,
    date: data?.date?.slice(0, 10),
    productName: data?.productId?.name,
    quantity: data?.quantity,
    stockType: data?.stockType,
    type: data?.type,
  }));
  return (
    <React.Fragment>
      <style>
        {`
    select, input[type=date] {
      text-align-last: center;
    }

  `}
      </style>
      <div className="mt-4 flex justify-start flex-wrap gap-4">
      <Dropdown
        label="Select Category"
        items={['TODAY', 'IN', 'OUT']}
        onSelect={handleSelectCategory}
        onClear={handleClearCategory}
        selectedValue={selectedCategory}
      />
      <Dropdown
        label="Stock Type"
        items={['Adjustment', 'New Order', 'Return', 'Consumed', 'New Product', 'Sold']}
        onSelect={handleStockTypeSelect}
        onClear={handleClearStockType}
        selectedValue={stockType}
      />
      <Dropdown
        label="Created By"
        items={staffList}
        itemName="staffList"
        onSelect={handleSelectCreatedBy}
        onClear={handleClearCreatedBy}
        selectedValue={createdBy}
      />

      </div>
      <div className="mx-auto mt-4">

        <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg flex flex-wrap justify-between">

          <div className="font-bold text-2xl tracking-wide">
            Stock Ledger
          </div>

          <div className="flex items-center justify-between gap-2 flex-wrap">

            <div className="flex items-center gap-2">
              <div className='flex gap-2 items-center'>
                <span className="text-gray-600 mr-2">From</span>
                <input
                  value={from}
                  onChange={(e) => {
                    setFrom(e.target.value);
                    setCurrentPage(1);
                  }}
                  type="date"
                  className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
                />
              </div>

              <div className='flex gap-2 items-center'>
                <span className="text-gray-600 mr-2">To</span>
                <input
                  value={to}
                  onChange={(e) => {
                    setTo(e.target.value);
                    setCurrentPage(1);
                  }}
                  type="date"
                  className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
                />
              </div>

              <div
                onClick={handleClearDateFilter}
                className="cursor-pointer flex content-center items-center justify-center rounded-full shadow-md shadow-[#ffdcc7]"
              >
                <img src={cross} alt="Clear" className='w-8 h-8' />
              </div>
            </div>

          </div>

          <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
            <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
              <DownloadTableData
                rowData={rowData}
                headerData={headerData}
              />

            </div>
          </div>

        </div>
        {isLoading ? (
          <SkeletonTableLoader />
        ) : ledgers?.length === 0 ?
          (<div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Ledger found
          </div>)
          : (
            <div className="shadow rounded-t-lg overflow-x-auto">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Sl
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Product Name
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Type
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Stock Type
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Quantity
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Created By
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Date
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Stock Befor/After
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ledgers?.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {((currentPage - 1) * 10) + i + 1}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.productId?.name}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.type}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.stockType}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.quantity || v?.volume} {v?.volume && "ml"}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.createdBy?.name}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="whitespace-no-wrap flex">
                            {v?.date?.slice(0, 10)}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.beforeStock} / {v?.afterStock}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
          <span className="text-xs xs:text-sm text-gray-900">
            Showing {((currentPage - 1) * 10) + 1} to{" "}
            {((currentPage - 1) * 10) + ledgers.length} of {totalItems} Entries
          </span>
          <div className="inline-flex mt-2 xs:mt-0">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`text-sm ${currentPage === 1
                ? "bg-gray-200"
                : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-l`}
            >
              Prev
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`text-sm ${currentPage === totalPages
                ? "bg-gray-200"
                : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-r`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default StockLedger