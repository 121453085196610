import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InvoiceDropdown from "../Invoices/InvoiceModal/InvoiceDropdown";
import axios from "axios";

const AppointmentTable = ({ handleRowClick, formatTime }) => {
  const branch = useSelector(state => state?.main?.branch) || "";
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterType, setFilterType] = useState("scheduledAt");
  const itemsPerPage = 3;

  const appointmentDetailFromCalendar = useSelector(
    (state) => state?.main?.appointmentDetailFromCalendar
  );

  // Filter options
  const FILTER_OPTIONS = [
    { value: "scheduledAt", label: "Up-Booking" },
    { value: "createdAt", label: "Booking" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "rescheduled", label: "Rescheduled" },
    { value: "cancelled", label: "Cancelled" },
    { value: "inquiry", label: "Quick Inquiry" }
  ];

  const fetchAppointments = async () => {
    const token = "Bearer " + localStorage.getItem("refresh_token");
    if (!token) return;

    setLoading(true);
    try {
      // Get selected date from calendar
      const selectedDate = new Date(appointmentDetailFromCalendar?.selectedDate);
      const selectedToDate = new Date(appointmentDetailFromCalendar?.selectedToDate);
      const fromDate = selectedDate.toISOString()?.split('T')[0];
      const toDate = selectedToDate.toISOString()?.split('T')[0];

      let url = `${process.env.REACT_APP_API_URL}/appointment/all-appointments?page=${currentPage}&limit=${itemsPerPage}&branch=${branch?._id}`;

      url += `&filterType=${filterType}&fromDate=${fromDate}&toDate=${toDate}`;
      const config = {
        method: "get",
        url: url,
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.request(config);

      // Check if data is returned and update state accordingly
      if (response.data.success && response.data.data.length > 0) {
        setAppointments(response.data.data);
        setTotalRecords(response.data.pagination?.totalRecords || 0);
      } else {
        setAppointments([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
      setAppointments([]); // Clear the appointments on error
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchAppointments();
  }, [currentPage, filterType, appointmentDetailFromCalendar?.selectedDate, appointmentDetailFromCalendar?.selectedToDate]);

  const handleFilterChange = (value) => {
    setFilterType(value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <div className="px-5 py-5 bg-white w-full border border-collapse">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <div className="font-bold text-2xl tracking-wide pb-5 md:pb-0">
            Appointments
          </div>

          <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto">
            <div className="relative md:w-48">
              <InvoiceDropdown
                items={FILTER_OPTIONS.map((option) => option.label)}
                onSelect={(label) => {
                  const option = FILTER_OPTIONS.find(
                    (opt) => opt.label === label
                  );
                  handleFilterChange(option.value);
                }}
                autoComplete="off"
                selectedItem={
                  FILTER_OPTIONS.find((opt) => opt.value === filterType)?.label
                }
                label={"Select Filter"}
                className="w-48"
              />
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : appointments?.length > 0 ? (
        <div>
          <div className="rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal border border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Created At
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Scheduled Date
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Time
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Phone
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Status
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Services
                  </th>
                </tr>
              </thead>
              <tbody>
                {appointments?.map((appo, index) => (
                  <tr
                    key={`appo-${index}`}
                    className={`${appo?.isDeleted ||
                      appo.status === "cancelled" ||
                      appo.status === "completed"
                      ? "hover:bg-gray-100 border-b border-gray-200 opacity-50"
                      : "hover:bg-gray-100 border-b border-gray-200"
                      } py-4 cursor-pointer`}
                    onClick={(e) => handleRowClick(e, appo)}
                  >
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {appo?.createdAt?.slice(0, 10)}
                      </div>
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {appo?.appointmentDate?.slice(0, 10)}
                      </div>
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {formatTime(appo?.appointmentDate)}
                      </div>
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {appo?.customerPhone?.slice(3)}
                      </div>
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {appo?.customerName}
                      </div>
                    </td>
                    <td className="px-5 capitalize py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {appo?.status}
                      </div>
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <ul className="list-disc list-inside">
                        {appo?.services?.map((service, index) => (
                          <li
                            key={`service-${index}`}
                            className="py-1 flex items-center"
                          >
                            <span className="font-semibold uppercase">
                              {service?.serviceId?.name}
                            </span>
                            <span className="mx-2 text-gray-500"> --- </span>
                            <span className="textlue-600">
                              {service?.staffId?.userId?.firstName}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg border border-collapse overflow-x-auto">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, totalRecords)} of{" "}
              {totalRecords} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage === Math.ceil(totalRecords / itemsPerPage)
                }
                className={`text-sm ${currentPage === Math.ceil(totalRecords / itemsPerPage)
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center text-xl font-bold">
          No Appointments Found
        </div>
      )}
    </div>
  );

};

export default AppointmentTable;