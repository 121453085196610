import { useState } from "react";
import { useSelector } from "react-redux";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import {
  allAppointmentList,
  setAppointmentFromCalendar,
} from "../../state-management/main/actions";
import { useDispatch } from "react-redux";
import { tostifySuccess } from "../../Screens/Common/tostifyMessages";
import {
  appointmentWithoutLimit,
} from "../../Screens/Common/initData";
import AppointmentTable from "./AppointmentTable";

function EventCalendar({ setVisible, showAppointments }) {
  const allAppointmentList = useSelector(
    (state) => state?.main?.appointmentList
  );
  const [selectedRow, setSelectedRow] = useState(null);
  const [appointment, setAppointment] = useState({});
  const appointmentDetailFromCalendar = useSelector(
    (state) => state?.main?.appointmentDetailFromCalendar
  );
  const selectedDate = new Date(appointmentDetailFromCalendar?.selectedDate);
  const selectedToDate = new Date(appointmentDetailFromCalendar?.selectedToDate);

  const formattedDate = selectedDate?.toLocaleDateString("en-US", {
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const formatteTodDate = selectedToDate?.toLocaleDateString("en-US", {
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const handleRowClick = (e, appointment) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;
    const containerWidth = rect.width;
    const elementWidth = 450;
    let adjustedMouseX = mouseX;
    if (mouseX + elementWidth > containerWidth) {
      adjustedMouseX = containerWidth - elementWidth;
      if (adjustedMouseX < 0) adjustedMouseX = 0;
    }
    const row = e.currentTarget;
    const rowData = {
      time: appointment?.appointmentDate,
      phone: appointment?.customerPhone,
      name: appointment?.customerName,
      services: appointment?.services,
      status: appointment?.status,
      gender: appointment?.gender,
      note: appointment?.note,
      email: appointment?.email,
      id: appointment?._id,
      deletedReason: appointment?.deletedReason,
      isDeleted: appointment?.isDeleted,
    };
    setAppointment(appointment);
    setSelectedRow({ ...rowData, adjustedMouseX, mouseY });
  };

  const selectedDateISOString = selectedDate?.setDate(
    selectedDate?.getDate() - 1
  );
  const filterDate = `${new Date(
    selectedDateISOString
  ).getFullYear()}-${new Date(selectedDateISOString).getMonth()}-${new Date(
    selectedDateISOString
  ).getDate()}`;

  let filteredAppointments;

  const filteredData = allAppointmentList?.filter((appointment) => {
    const appointmentDate = `${new Date(
      appointment?.appointmentDate
    ).getFullYear()}-${new Date(
      appointment?.appointmentDate
    ).getMonth()}-${new Date(appointment?.appointmentDate).getDate()}`;
    return appointmentDate === filterDate;
  });

  filteredAppointments = filteredData?.length > 0 ? filteredData : [];

  const formatTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <div className={`my-4 relative overflow-scroll no-scrollbar transition-all duration-700 ease-in-out ${showAppointments ? "max-h-[500px]" : "max-h-0"
      }`}>
      {
        <div className=" bg-white rounded-md p-4">
          <div className="">
            <div className="flex justify-between">
              <div className="text-lg font-semibold flex gap-4">
                <h1 className="text-lg font-semibold  text-[#fe8740]">
                  Appointments for {formattedDate} to  {formatteTodDate}
                </h1>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-2 mb-2">
              <AppointmentTable
                handleRowClick={handleRowClick}
                formatTime={formatTime}
              />
            </div>

            {selectedRow && (
              <TooltipModal
                data={selectedRow}
                setVisible={setVisible}
                onClose={() => setSelectedRow(null)}
                formatTime={formatTime}
                appointment={appointment}
                setAppointment={setAppointment}
              />
            )}
          </div>
        </div>
      }
    </div>
  );
}

export default EventCalendar;

const TooltipModal = ({
  data,
  onClose,
  formatTime,
  appointment,
  setAppointment,
  setVisible,
}) => {
  const [deletedReason, setDeletedReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [deletedErr, setDeletedErr] = useState(false);
  const { adjustedMouseX, mouseY } = data;
  const dispatch = useDispatch();

  const allApointmentData = async () => {
    const data = await appointmentWithoutLimit();
    dispatch(allAppointmentList(data));
  };
  const token = "Bearer " + localStorage.getItem("refresh_token");

  const CancelAppointment = async (appointment) => {
    if (!deletedReason) {
      setDeletedErr(true);
      return;
    }
    let data = JSON.stringify({
      deletedReason: deletedReason,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/appointment/delete/${appointment?.id}`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setLoading(true);
    axios
      .request(config)
      .then(async (response) => {
        setDeletedReason("");
        tostifySuccess(response?.data?.message);
        await allApointmentData();
        onClose();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <div
      className="absolute z-50 bg-white rounded-lg p-4 shadow-lg"
      style={{ top: mouseY, left: adjustedMouseX, width: "350px" }}
    >
      <button
        className="absolute top-0 right-0 mr-5 mt-5 text-gray-500 hover:text-gray-700 text-2xl"
        onClick={() => {
          setAppointment({});
          onClose();
        }}
      >
        x
      </button>
      <div className="text-lg font-semibold mb-2">Appointment Details</div>
      <div className="mb-2">Time: {formatTime(data?.time)}</div>
      <div className="mb-2">Name: {data?.name}</div>
      <div className="mb-2">Phone: {data?.phone?.slice(3, 13)}</div>
      <div className="mb-2">Status: {data?.status}</div>

      {data?.isDeleted && (
        <div className="mb-2">
          Canceled Reason:{" "}
          <span className="ml-2 tracking-wide text-justify text-red-500">
            {data?.deletedReason}
          </span>
        </div>
      )}
      {/* {1 && (
        <div className="mb-2">
          Reschedule:{" "}
          <span className="ml-2 tracking-wide text-justify">
            {data?.time?.slice(0, 10)}
          </span>
        </div>
      )} */}
      <div className="mb-2">
        Services:
        <ul>
          {data?.services?.map((service, index) => (
            <li key={`service-${index}`}>{service?.serviceId?.name}</li>
          ))}
        </ul>
      </div>

      {!data?.isDeleted || !data?.status === "cancelled" || !data?.status === "completed" && (
        <textarea
          className={
            deletedErr
              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
          }
          onChange={(e) => {
            setDeletedErr(false);
            setDeletedReason(e.target.value);
          }}
          placeholder="Enter Cancelation Reason"
          name="deletedReason"
          id="deletedReason"
          cols="30"
          rows="2"
        ></textarea>
      )}
      {!(
        data?.isDeleted ||
        data?.status === "cancelled" ||
        data?.status === "completed"
      ) && (
          <div className="flex gap-3 flex-wrap mt-4">
            <button
              className={`${data?.isDeleted ||
                data?.status === "cancelled" ||
                data?.status === "completed"
                ? "bg-blue-200 hover:bg-blue-100"
                : "bg-blue-500 hover:bg-blue-700"
                } text-white font-bold py-2 w-32 rounded`}
              disabled={
                data?.isDeleted ||
                data?.status === "cancelled" ||
                data?.status === "completed"
              }
              onClick={() => {
                dispatch(
                  setAppointmentFromCalendar({
                    appointment: appointment,
                    selectedItem: "Appointment",
                    selectedDate: new Date(appointment?.appointmentDate),
                  })
                );
                onClose();
                setVisible(false);
              }}
            >
              Reschedule
            </button>

            <button
              className={`${data?.isDeleted ||
                data?.status === "cancelled" ||
                data?.status === "completed"
                ? "bg-green-200 hover:bg-green-100"
                : "bg-green-500 hover:bg-green-700"
                } text-sm text-center text-white font-bold py-2 w-32 rounded`}
              disabled={
                data?.isDeleted ||
                data?.status === "cancelled" ||
                data?.status === "completed"
              }
              onClick={() => {
                dispatch(
                  setAppointmentFromCalendar({
                    appointment: appointment,
                    selectedItem: "Bill",
                    selectedDate: new Date(appointment?.appointmentDate),
                    selectedToDate: new Date()
                  })
                );
                onClose();
                setVisible(false);
              }}
            >
              Proceed To Bill
            </button>

            <button
              onClick={() => CancelAppointment(data)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 w-32 rounded flex justify-center items-center"
            >
              {loading ? (
                <div className="w-6 h-6 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
              ) : (
                <span
                  disabled={
                    data?.isDeleted ||
                    data?.status === "cancelled" ||
                    data?.status === "completed"
                  }
                >Cancel</span>
              )}
            </button>
          </div>
        )}

    </div>
  );
};
