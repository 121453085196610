import React, { useState, useEffect, useRef } from 'react';

const ReportFilterType = ({ setSelectedCategory, subcategory, setEndDate, setStartDate }) => {
  const [selectedType, setSelectedType] = useState("month");
  const [isCustomRange, setIsCustomRange] = useState(false);
  // const [isConfirmed, setIsConfirmed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const items = ["month", "day", "week", "customRange"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCategoryChange = (category) => {
    setSelectedType(category);
    setSelectedCategory(category)
    setIsCustomRange(category === "customRange");
    // setIsConfirmed(false);
    setIsOpen(false);
    setEndDate("");
    setStartDate("");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="mt-4 flex justify-between items-center">
        <div className="flex flex-col gap-5">
          <div className="relative inline-block text-left" ref={dropdownRef}>
            <div className="dropdown inline-block relative rounded-lg">
              <button
                onClick={toggleDropdown}
                className="text-[#132c4a] border-0 placeholder-[#718092] min-w-24 bg-white text-sm shadow font-semibold py-3 px-5 rounded inline-flex text-center gap-4 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
              >
                <span className="mr-1">{selectedType ? selectedType?.charAt(0)?.toUpperCase() + selectedType?.slice(1) : 'Select Report Type'}</span>
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </button>
              <ul className={`dropdown-menu absolute top-12 ${isOpen ? '' : 'hidden'} pt-1 shadow min-w-40 max-h-60 z-40 overflow-y-auto rounded-lg bg-white`}>
                {items?.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleCategoryChange(item)}
                    className={`text-[#132c4a] capitalize hover:text-white ${index === 0 ? 'rounded-t' : index === items.length - 1 ? 'rounded-b' : ''} cursor-pointer bg-white hover:bg-primaryColor py-2 px-4 block whitespace-no-wrap`}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {isCustomRange && (
            <div className="flex gap-4 items-center justify-center">
              <div className="flex gap-8 items-center">
                <label htmlFor="startDate">From Date</label>
                <input
                  id="startDate"
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                  className="rounded-md text-[#464E5F] bg-white hover:text-primaryColor px-4 py-2 transition duration-300 ease-in-out border"
                />
              </div>
              <div className="flex gap-8 items-center">
                <label htmlFor="endDate">To Date</label>
                <input
                  id="endDate"
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                  className="rounded-md text-[#464E5F] bg-white hover:text-primaryColor px-4 py-2 transition duration-300 ease-in-out border"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportFilterType;
