import React, { useEffect, useState } from "react";
import { fetchExpanditure } from "../../../Screens/Common/initData";
import { useSelector } from "react-redux";
import { ReportsDynamicTable } from "../ReportsDynamicTable";
import excel from "../../../Assets/xls.png";
import pdf from "../../../Assets/Pdf.png";
import ReportFilterType from "../ReportFilterType";
import Dropdown from "../../../Screens/Common/Dropdown";
import DownloadReport from "../DownloadReport";

const ExpenseReport = () => {
  const [selectedCategory, setSelectedCategory] = useState("month");
  const [startDate, setStartDate] = useState("");
  const [subcategory, setsubcategory] = useState("");
  const [endDate, setEndDate] = useState("");
  const [expenditureData, setExpenditureData] = useState([]);
  const branch = useSelector((state) => state?.main?.branch);

  const today = new Date();
  const last7days = new Date(today);
  last7days.setDate(today.getDate() - 7);
  const last30days = new Date(today);
  last30days.setDate(today.getDate() - 30);

  useEffect(() => {
    getExpenditures();
  }, [branch]);
  const getExpenditures = async () => {
    const data = await fetchExpanditure();
    setExpenditureData(data);
  };
  const filteredExpenses =
    selectedCategory === "day"
      ? expenditureData?.filter(
        (item) => new Date(item?.date).toISOString() === today.toISOString()
      )
      : selectedCategory === "week"
        ? expenditureData?.filter((item) => new Date(item?.date) >= last7days)
        : selectedCategory === "month"
          ? expenditureData?.filter((item) => new Date(item?.date) >= last30days)
          : selectedCategory === "customRange"
            ? expenditureData?.filter((item) => {
              const itemDate = new Date(item?.date).getTime();
              const startTimestamp = new Date(startDate).getTime();
              const endTimestamp = new Date(endDate).getTime();
              return itemDate >= startTimestamp && itemDate <= endTimestamp;
            })
            : expenditureData;

  const filteredHeaerExpenseData = [
    { label: "Date", field: "date" },
    { label: "Card", field: "card" },
    { label: "Cash", field: "cash" },
    { label: "Expense", field: "expense" },
    { label: "Remark", field: "remark" },
  ];

  const filteredRowExpenseData = filteredExpenses?.map((item, index) => ({
    date: item.date,
    card: item.card,
    cash: item.cash,
    expense: item.expense,
    remark: item.remark,
  }));

  const handleSelect = (value) => {
    setsubcategory(value);
  };
  const handleClearSubCategory = () => {
    setsubcategory("");
  }
  return (
    <>
      <div className="px-5 py-5 bg-white min-w-full border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4 mt-6">
        <div className="font-bold text-2xl tracking-wide lg:block hidden">
          Reports
        </div>

        <div>
          <ReportFilterType
            setSelectedCategory={setSelectedCategory}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            subcategory={subcategory}
          />
        </div>

        <div>
          <Dropdown
            label="All"
            items={["All", "expenses"]}
            onSelect={handleSelect}
            onClear={handleClearSubCategory}
          />
        </div>

        <DownloadReport
          rowData={filteredRowExpenseData}
          headerData={filteredHeaerExpenseData}
        />
      </div>
      {subcategory === "expenses" && (
        <ReportsDynamicTable
          rowData={filteredRowExpenseData}
          headerData={filteredHeaerExpenseData}
        />
      )}
      {subcategory === "All" && (
        <ReportsDynamicTable
          rowData={filteredRowExpenseData}
          headerData={filteredHeaerExpenseData}
        />
      )}
    </>
  );
};

export default ExpenseReport;
